import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import Table from '@app/src/components/Table'
import usePagination from '@app/src/hooks/pagination'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import useSort from '@app/src/hooks/sorting'
import { StatisticsByTemplateSectionModel } from '@app/src/pages/ResourceCollection/Collections/DataHub/Reports/DataHubReport'
import {
  REPORT_TEMPLATE_ID_AND_PERIOD,
  filtersWithoutTemplateIdAndPeriod,
  getTemplateIdAndPeriodFromFilter,
} from '@app/src/pages/ResourceCollection/Filters/StatisticsConstants'
import {
  FilterGroup,
  Operators,
  RESPONSE_LATEST_SUBMITTED_FILTER,
} from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { Response } from '@app/src/types/resourceExplorer'
import React, { useMemo } from 'react'
import { tableViewAllowedFilters } from '../../DataHubScene'
import ReportTableHeader from './ReportTableHeader'
import ReportTableRow from './ReportTableRow'

type ReportTableProps = {
  filters: FilterGroup[]
  sections: StatisticsByTemplateSectionModel[]
}

const transformFilterNames = (filter: FilterGroup[]) =>
  filter.map(filter => {
    const responseFilter = filter.name.startsWith('response')
    return responseFilter ? { ...filter, name: filter.name.replace('response.', '') } : filter
  })

const ReportTable: React.FC<ReportTableProps> = ({ sections }) => {
  const { sorting, toggleSorting } = useSort()
  const [page, pageSize, setPage, setPageSize] = usePagination({ page: 1, pageSize: 50 })
  const filters = useGetApiQueryFilters(tableViewAllowedFilters)

  const cleanFilters = filtersWithoutTemplateIdAndPeriod(filters)
  const renamedFilters = transformFilterNames(cleanFilters)
  const { periodName, templateId } = getTemplateIdAndPeriodFromFilter(filters, REPORT_TEMPLATE_ID_AND_PERIOD)

  const { items, isLoading, count, isError } = useFetchCollectionWithPost<Response>({
    endpoint: endpoints.responses,
    key: FetchKey.Response,
    payload: {
      filter: [
        ...renamedFilters,
        {
          name: 'request.questionnaireTemplateId',
          filters: [
            {
              operator: Operators.EqualTo,
              value: templateId,
            },
          ],
        },
        {
          name: 'request.periodName',
          filters: [
            {
              operator: Operators.EqualTo,
              value: periodName,
            },
          ],
        },
        RESPONSE_LATEST_SUBMITTED_FILTER,
      ],
      sort: sorting,
      include: [
        'responseAnalytics',
        'request',
        'request.template',
        'request.subscriptions.target',
        'items.requestItem.unit.symbol',
        'items.flagRule.creatorOrganization',
        'items.flagRule.suggestedRiskGuidance.suggestedRiskGuidanceLinks',
        'items.flagRule.suggestedRiskGuidance.suggestedRiskNaceCodes.naceCode',
      ],
      pagination: {
        itemsPerPage: pageSize,
        pageNumber: page,
      },
    },
    options: { enabled: Boolean(templateId) },
  })

  const requestItemsTitles = useMemo(() => items?.[0]?.items?.map(item => item?.requestItem?.description), [items])
  const hasFlags = Boolean(items.some(item => Boolean(item.responseAnalytics?.flagCount)))

  return (
    <Table<Response>
      HeaderComponent={() => (
        <ReportTableHeader
          requestItems={requestItemsTitles}
          toggleSorting={toggleSorting}
          activeSorting={sorting}
          hasFlags={hasFlags}
        />
      )}
      RowComponent={({ row }) => <ReportTableRow row={row} hasFlags={hasFlags} sections={sections} />}
      count={count}
      data={items}
      isLoading={isLoading}
      isError={isError}
      page={page}
      pageSize={pageSize}
      setPage={setPage}
      setPageSize={setPageSize}
      stickyColumnIndex={1}
    />
  )
}

export default ReportTable
