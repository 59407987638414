import { alpha, Box, Button, useTheme } from '@mui/material'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewFlagRule from '@app/src/components/Drawer/Views/DrawerViewFlagRule'
import FlagAvatar from '@app/src/components/Flags/FlagAvatar'
import { AvatarSize } from '@app/src/components/Ui/Avatar'
import React from 'react'
import { useIntl } from 'react-intl'
import { ReportFlagRule } from '@app/src/types/flags'

interface CustomQuestionFlagIndicatorProps {
  flaggedCount: number
  flagRules?: ReportFlagRule[]
}

const CustomQuestionFlagIndicator: React.FC<CustomQuestionFlagIndicatorProps> = ({ flaggedCount, flagRules }) => {
  const { openDrawer } = useDrawer()
  const { palette } = useTheme()
  const { formatMessage } = useIntl()

  return (
    <Box
      bgcolor={alpha(palette.error.main, 0.12)}
      width="100%"
      px={2}
      py={1}
      display="flex"
      alignItems="center"
      borderRadius={0.5}
      mb={5}
    >
      <FlagAvatar size={AvatarSize.Small} />
      <Box flex={1} ml={1}>
        {formatMessage(
          { id: 'statistics.answersReportedAsFlaggedRisk' },
          {
            flagCount: flaggedCount,
          },
        )}
      </Box>

      {Boolean(flagRules?.length) && (
        <Button variant="text" onClick={() => openDrawer(<DrawerViewFlagRule flagRules={flagRules} />)}>
          {formatMessage({ id: 'statistics.showDetails' })}
        </Button>
      )}
    </Box>
  )
}

export default CustomQuestionFlagIndicator
