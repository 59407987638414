import FlagAvatar from '@app/src/components/Flags/FlagAvatar'
import FlagSuggestedRiskAvatar from '@app/src/components/Flags/FlagSuggestedRiskAvatar'
import { AvatarSize } from '@app/src/components/Ui/Avatar'
import { useDialogState } from '@app/src/hooks/mui-hooks'
import { StatisticsByTemplateSectionModel } from '@app/src/pages/ResourceCollection/Collections/DataHub/Reports/DataHubReport'
import { WF_ORGANIZATION_ID } from '@app/src/wf-constants'
import { Box, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import FlagOverviewDialog from './FlagOverviewDialog'

interface ReportOverviewFlagIndicatorProps {
  statisticsSections: StatisticsByTemplateSectionModel[]
  flaggedCount: number
}

const ReportOverviewFlagPopover: React.FC<ReportOverviewFlagIndicatorProps> = ({
  statisticsSections,
  flaggedCount,
}) => {
  const { formatMessage } = useIntl()
  const [open, openDialog, closeDialog] = useDialogState(false)

  const isWorldfavorRule = statisticsSections.some(r =>
    r.items.some(i =>
      i.flagResult?.reportFlagRules?.some(flagRule => flagRule.creatorOrganizationId === WF_ORGANIZATION_ID),
    ),
  )

  return (
    <>
      <Box display="flex" alignItems="center">
        {!isWorldfavorRule ? (
          <FlagAvatar size={AvatarSize.Medium} onClick={openDialog} />
        ) : (
          <FlagSuggestedRiskAvatar size={AvatarSize.Medium} onClick={openDialog} />
        )}

        <Typography ml={1} variant="h2">
          {flaggedCount}
        </Typography>
      </Box>

      <FlagOverviewDialog
        open={open}
        title={formatMessage(
          { id: isWorldfavorRule ? 'statistics.suggestedRisksWithCount' : 'statistics.flaggedRisksWithCount' },
          { count: flaggedCount },
        )}
        onClose={closeDialog}
        statisticsSections={statisticsSections}
      />
    </>
  )
}

export default ReportOverviewFlagPopover
