import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchPost } from '@app/src/api/fetchHooks'
import EmptyState from '@app/src/components/EmptyState/EmptyState'
import { EmptyStateStatistics } from '@app/src/pages/ResourceCollection/Collections/DataHub/EmptyStateStatisticsProps'
import { REPORT_TEMPLATE_ID_AND_PERIOD } from '@app/src/pages/ResourceCollection/Filters/StatisticsConstants'
import { FilterGroup } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { ReportFlagRule } from '@app/src/types/flags'
import { Unit } from '@app/src/types/resourceExplorer'
import { br } from '@app/src/utils/translationMarkup'
import { BarChart } from '@mui/icons-material'
import { Box, CircularProgress } from '@mui/material'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { ViewType } from '../../DataHubScene'
import ReportGraphs from './ReportGraphs'
import ReportOverview from './ReportOverview'
import ReportTable from './ReportTable'

export interface FlagResult {
  flaggedCount: number
  reportFlagRules?: ReportFlagRule[]
}

export interface OptionItemResult {
  count: number
  name: string
  percentage: number
  isOther?: boolean
}

export interface OptionsResult {
  items: OptionItemResult[]
  totalCount: number
}

interface OptionStatisticsView {
  maxItemCount: number
  items: OptionResultItemMultiPeriod[]
  valuesInOther: string[]
}

export interface OptionResultItemMultiPeriod {
  count: number
  name: string
  isOther: boolean
}

export interface FileResult {
  expirationDateItems: FileExpirationDateItemResultModel[]
  totalCount: number
}

export interface FileExpirationDateItemResultModel {
  expirationDateInterval: string
  amount: number
  percentage: number
}

export interface NumberResult {
  min: number
  average: number
  max: number
  sum: number
}

export interface AnswerStat {
  number: number
  percentage: number
}

export interface QuestionMonitoring {
  companiesHaveAnswered: AnswerStat
  companiesHaveNotAnswered: AnswerStat
  companiesPlanToIntroduce: AnswerStat
}

export interface StatisticsByQuestionsQuery {
  filters: FilterGroup[]
  targetOptionResult: string
  excludeExpirationDatesAndFlags: boolean
}

export interface ResponseItemStatisticsByTemplateModel {
  id: number
  title: string
  questionType: string
  unit: Unit
  questionMonitoring: QuestionMonitoring
  optionsResult: OptionsResult
  numberResult: NumberResult | null
  fileResult: FileResult
  periodName: string
  flagResult: FlagResult
}

export interface StatisticsForQuestionPerPeriod {
  id: number
  title: string
  questionType: string
  isMultiChoice: boolean
  unit: Unit
  resultForPeriods: ResultForPeriod[]
}

export interface ResultForPeriod {
  period: string
  questionMonitoring: QuestionMonitoring
  unansweredQuestionCount?: number
  optionStatisticsViews?: OptionStatisticsView[]
  numberResult?: NumberResult
  fileResult?: FileResult
}

export interface StatisticsByTemplateSectionModel {
  order: number
  title: string
  description: string
  items: ResponseItemStatisticsByTemplateModel[]
}

export interface DataHubReportProps {
  viewType: ViewType
  filters: FilterGroup[]
}

const DataHubReport: React.FC<DataHubReportProps> = ({ viewType = ViewType.GRAPH, filters }): JSX.Element => {
  const { formatMessage } = useIntl()

  const selectedTemplateAndPeriodFilter = useMemo(
    () => filters.find(filter => filter.name === REPORT_TEMPLATE_ID_AND_PERIOD),
    [filters],
  )
  const templateHasBeenSelected = selectedTemplateAndPeriodFilter !== undefined

  const { data: sections, isLoading } = useFetchPost<StatisticsByTemplateSectionModel[], FilterGroup[]>({
    payload: filters,
    endpoint: endpoints.responseItemsWithStatisticsByTemplate,
    key: FetchKey.RequestTemplate,
    options: { enabled: templateHasBeenSelected },
  })

  if (!templateHasBeenSelected) {
    return <EmptyStateStatistics message={formatMessage({ id: 'statistics.emptyStateReport' }, { br })} />
  }

  if (isLoading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" pr={8} flexGrow={1}>
        <CircularProgress size={36} color="primary" />
      </Box>
    )
  }

  if (!sections)
    return (
      <EmptyState
        iconComponent={BarChart}
        title={formatMessage({ id: 'table.emptyStateTitle' })}
        description={formatMessage({ id: 'table.emptyStateDescription' })}
        sx={{ my: 2 }}
      />
    )

  return (
    <>
      <Box pr={5} py={2}>
        <ReportOverview viewType={viewType} statisticsSections={sections} />
      </Box>

      {viewType === ViewType.GRAPH ? (
        <ReportGraphs sections={sections} filters={filters} />
      ) : (
        <ReportTable filters={filters} sections={sections} />
      )}
    </>
  )
}

export default DataHubReport
