import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import CompanyCell from '@app/src/components/Table/Cells/CompanyCell'
import { STATISTICS_SPECIAL_FILTERS } from '@app/src/pages/ResourceCollection/Filters/StatisticsConstants'
import {
  FilterGroup,
  Operators,
  RESPONSE_ITEM_LATEST_SUBMITTED_FILTER,
} from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { ResponseItem } from '@app/src/types/resourceExplorer'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, CircularProgress, Grid, Typography } from '@mui/material'
import { alpha } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'

const useStyles = makeStyles(({ spacing, palette }) => ({
  container: {
    maxHeight: 400,
  },
  itemContainer: {
    backgroundColor: alpha(palette.common.black, 0.04),
    padding: spacing(3),
    borderRadius: spacing(1),
  },
  accordion: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}))

interface TextAnswersProps {
  questionId: number
  filters: FilterGroup[]
  periodName: string
}

const TextAnswers: React.FC<TextAnswersProps> = ({ questionId, periodName, filters }) => {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(false)
  const { formatMessage } = useIntl()

  const { items, isLoading: responseItemLoading } = useFetchCollectionWithPost<ResponseItem>({
    key: [FetchKey.Answer, questionId],
    endpoint: endpoints.responseItemsCollection,
    payload: {
      filter: [
        ...filters.filter(fi => !STATISTICS_SPECIAL_FILTERS.includes(fi.name)),
        {
          name: 'requestItem.template.id',
          filters: [
            {
              value: questionId,
              operator: Operators.EqualTo,
            },
          ],
        },
        {
          name: 'cannotAnswer',
          filters: [
            {
              value: false,
              operator: Operators.EqualTo,
            },
          ],
        },
        {
          name: 'response.request.periodName',
          filters: [
            {
              value: periodName,
              operator: Operators.EqualTo,
            },
          ],
        },
        RESPONSE_ITEM_LATEST_SUBMITTED_FILTER,
      ],
      pagination: {
        pageNumber: 1,
        itemsPerPage: 9999,
      },
      include: [
        'creatorOrganization.image',
        'response.request.subscriptions.target',
        'response.request.subscriptions.target.image',
      ],
    },
  })

  if (responseItemLoading) {
    return (
      <Box height={100} display="flex" justifyContent="center" alignItems="center">
        <CircularProgress size={24} />
      </Box>
    )
  }

  const expansionEnabled = items.length > 3

  return (
    <Box display="flex" flexDirection="column">
      <Box
        flexGrow={1}
        overflow="hidden"
        className={classNames({ [classes.container]: expansionEnabled && !expanded })}
      >
        <Grid container spacing={2}>
          {items.map(item => (
            <Grid item xs={12} key={item.id}>
              <Box className={classes.itemContainer}>
                <Typography paragraph color="textSecondary" variant="body1">
                  {item.answer}
                </Typography>
                <CompanyCell company={item.response.request.targetAliasObject} drawer disableCell />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
      {expansionEnabled && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="100%"
          color="text.secondary"
          onClick={() => setExpanded(expanded => !expanded)}
          className={classes.accordion}
          mt={2}
        >
          <Typography color="textSecondary">
            {formatMessage({ id: `general.${expanded ? 'showLess' : 'showAll'}` })}
          </Typography>
          {expanded ? (
            <ExpandLessIcon color="inherit" fontSize="small" />
          ) : (
            <ExpandMoreIcon color="inherit" fontSize="small" />
          )}
        </Box>
      )}
    </Box>
  )
}

export default TextAnswers
