import FlagAvatar from '@app/src/components/Flags/FlagAvatar'
import FlagSuggestedRiskAvatar from '@app/src/components/Flags/FlagSuggestedRiskAvatar'
import AnswerWithFlagCell from '@app/src/components/Table/Cells/AnswerWithFlagCell'
import CompanyCell from '@app/src/components/Table/Cells/CompanyCell'
import IconCell from '@app/src/components/Table/Cells/IconCell'
import { AvatarSize } from '@app/src/components/Ui/Avatar'
import { useDialogState } from '@app/src/hooks/mui-hooks'
import { ReportFlagType } from '@app/src/types/flags'
import { Response } from '@app/src/types/resourceExplorer'
import { WF_ORGANIZATION_ID } from '@app/src/wf-constants'
import { TableRow } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { StatisticsByTemplateSectionModel } from './DataHubReport'
import FlagOverviewDialog from './FlagOverviewDialog'

type ReportTableRowProps = {
  row: Response
  hasFlags: boolean
  sections: StatisticsByTemplateSectionModel[]
}

const ReportTableRow: React.FC<ReportTableRowProps> = ({ row: response, hasFlags, sections }) => {
  const { formatMessage } = useIntl()
  const [open, openDialog, closeDialog] = useDialogState(false)

  const flagCount = response?.responseAnalytics?.flagCount
  const isWorldfavorRule =
    response.items.find(i => i?.flagRule?.creatorOrganizationId === WF_ORGANIZATION_ID) !== undefined

  const flagRuleTemplateItemId = response.items
    .filter(item => item.flag !== ReportFlagType.NotSet && item.flagRule?.questionId)
    .map(item => item.flagRule?.questionId)

  const filteredSections = sections.map(section => ({
    ...section,
    items: section.items.filter(item => flagRuleTemplateItemId.includes(item.id)),
  }))

  return (
    <TableRow>
      <CompanyCell company={response?.request?.targetAliasObject} drawer maxRows={2} />

      {hasFlags && (
        <>
          <IconCell
            icon={
              isWorldfavorRule ? (
                <FlagSuggestedRiskAvatar
                  size={AvatarSize.Small}
                  isEnabled={Boolean(flagCount)}
                  onClick={flagCount ? openDialog : undefined}
                />
              ) : (
                <FlagAvatar
                  size={AvatarSize.Small}
                  isEnabled={Boolean(flagCount)}
                  onClick={flagCount ? openDialog : undefined}
                />
              )
            }
            title={flagCount ?? 0}
          />
          <FlagOverviewDialog
            open={open}
            title={formatMessage(
              { id: isWorldfavorRule ? 'statistics.suggestedRisksWithCount' : 'statistics.flaggedRisksWithCount' },
              { count: flagCount },
            )}
            onClose={closeDialog}
            statisticsSections={filteredSections}
          />
        </>
      )}

      {...response.items.map(item => (
        <AnswerWithFlagCell
          item={item}
          key={item.id}
          maxRows={2}
          responderName={response?.request?.targetAliasObject.name}
        />
      ))}
    </TableRow>
  )
}

export default ReportTableRow
