import CustomQuestionFlagIndicator from '@app/src/pages/ResourceCollection/Collections/DataHub/CustomQuestionFlagIndicator'
import WfQuestionFlagIndicator from '@app/src/pages/ResourceCollection/Collections/DataHub/WfQuestionFlagIndicator'
import { ReportFlagRule } from '@app/src/types/flags'
import { WF_ORGANIZATION_ID } from '@app/src/wf-constants'
import React from 'react'

interface QuestionCardFlagIndicatorProps {
  flaggedCount: number
  flagRules?: ReportFlagRule[]
}

const QuestionCardFlagIndicator: React.FC<QuestionCardFlagIndicatorProps> = ({ flaggedCount, flagRules }) => {
  const isWorldfavorRule = flagRules?.some(flagRule => flagRule.creatorOrganizationId === WF_ORGANIZATION_ID)

  return (
    <>
      {!isWorldfavorRule ? (
        <CustomQuestionFlagIndicator flaggedCount={flaggedCount} flagRules={flagRules} />
      ) : (
        <WfQuestionFlagIndicator flaggedCount={flaggedCount} flagRules={flagRules} />
      )}
    </>
  )
}

export default QuestionCardFlagIndicator
