import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchPost } from '@app/src/api/fetchHooks'
import { EmptyStateStatistics } from '@app/src/pages/ResourceCollection/Collections/DataHub/EmptyStateStatisticsProps'
import QuestionCard from '@app/src/pages/ResourceCollection/Collections/DataHub/QuestionCard'
import DataHubCharts from '@app/src/pages/ResourceCollection/Collections/DataHub/QuestionTypeCharts/DataHubCharts'
import { MAX_OPTIONS_TO_SHOW } from '@app/src/pages/ResourceCollection/Collections/DataHub/QuestionTypeCharts/StatisticsConstants'
import { ResponseItemStatisticsByTemplateModel } from '@app/src/pages/ResourceCollection/Collections/DataHub/Reports/DataHubReport'
import { FilterGroup } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { br } from '@app/src/utils/translationMarkup'
import { Box, CircularProgress, Grid } from '@mui/material'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'

import EmptyState from '@app/src/components/EmptyState'
import { ITEM_TEMPLATE_ID_AND_PERIOD } from '@app/src/pages/ResourceCollection/Filters/StatisticsConstants'
import { BarChart } from '@mui/icons-material'

interface DataHubQuestionProps {
  filters: FilterGroup[]
}

interface StatisticsByQuestionsQuery {
  filters: FilterGroup[]
  targetOptionResult: string
  excludeExpirationDatesAndFlags: boolean
}

const DataHubQuestion: React.FC<DataHubQuestionProps> = ({ filters }) => {
  const { formatMessage } = useIntl()

  const selectedQuestionFilter = useMemo(
    () => filters.filter(filter => filter.name === ITEM_TEMPLATE_ID_AND_PERIOD),
    [filters],
  )
  const questionHasBeenSelected = selectedQuestionFilter.length > 0

  const { data: questions, isLoading } = useFetchPost<
    ResponseItemStatisticsByTemplateModel[],
    StatisticsByQuestionsQuery
  >({
    payload: {
      filters: filters,
      targetOptionResult: '',
      excludeExpirationDatesAndFlags: false,
    },
    endpoint: endpoints.responseItemsWithStatisticsByQuestions,
    key: [FetchKey.RequestTemplate],
    options: { enabled: questionHasBeenSelected },
  })

  if (!questionHasBeenSelected) {
    return <EmptyStateStatistics message={formatMessage({ id: 'statistics.emptyStateQuestion' }, { br })} />
  }

  if (isLoading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" p={20} borderRadius="8px">
        <CircularProgress size={36} color="primary" />
      </Box>
    )
  }

  if (!questions)
    return (
      <EmptyState
        iconComponent={BarChart}
        title={formatMessage({ id: 'table.emptyStateTitle' })}
        description={formatMessage({ id: 'table.emptyStateDescription' })}
        sx={{ my: 2 }}
      />
    )

  return (
    <Box pr={5} py={4}>
      <Grid container spacing={4} alignItems="center" justifyContent="center">
        <Grid container item lg={6} md={8} xs={10}>
          {questions?.map(question => (
            <Grid item key={question.id} xs={12} data-testid="question-card-DH">
              <QuestionCard
                question={`${question?.title} - ${question?.periodName}`}
                questionMonitoring={question?.questionMonitoring}
                questionType={question?.questionType}
                resultsText={formatMessage({
                  id: `statistics.${
                    question.optionsResult?.items.length > MAX_OPTIONS_TO_SHOW ? 'mostAnswered' : 'results'
                  }`,
                })}
                flagResult={question.flagResult}
              >
                <DataHubCharts
                  numberResult={question.numberResult}
                  questionType={question.questionType}
                  unit={question.unit}
                  filters={filters}
                  questionId={question.id}
                  optionsResult={question.optionsResult}
                  fileResult={question.fileResult}
                  periodName={question.periodName}
                  companiesHaveAnswered={question.questionMonitoring?.companiesHaveAnswered?.number ?? 0}
                />
              </QuestionCard>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Box>
  )
}

export default DataHubQuestion
