import DataHubFiltersQuestion from '@app/src/pages/ResourceCollection/Filters/DataHubFiltersQuestion'
import { FilterGroup } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import ResourceCollectionScene, {
  ResourceCollectionSceneProps,
} from '@app/src/pages/ResourceCollection/ResourceCollectionScene'
import React from 'react'
import { useIntl } from 'react-intl'
import DataHubQuestion from './DataHubQuestion'

type QuestionsProps = {
  tabs: ResourceCollectionSceneProps['tabs']
  allowedFilters: string[]
  relatedContentFilter?: FilterGroup[]
  userFilters: FilterGroup[]
}

const Questions: React.FC<QuestionsProps> = ({ tabs, allowedFilters, relatedContentFilter = [], userFilters }) => {
  const { formatMessage } = useIntl()

  return (
    <ResourceCollectionScene
      title={formatMessage({
        id: 'schemas.responseItem.responseItems',
      })}
      tabs={tabs}
      filter={<DataHubFiltersQuestion implicitFilters={relatedContentFilter} allowedFilters={allowedFilters} />}
      enableScroll
    >
      <DataHubQuestion filters={userFilters} />
    </ResourceCollectionScene>
  )
}

export default Questions
