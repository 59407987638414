import Markdown from '@app/src/components/Markdown'
import QuestionCardFlagIndicator from '@app/src/pages/ResourceCollection/Collections/DataHub/QuestionCardFlagIndicator'
import {
  FlagResult,
  QuestionMonitoring,
} from '@app/src/pages/ResourceCollection/Collections/DataHub/Reports/DataHubReport'
import { QuestionTypes } from '@app/src/wf-constants'
import { Box, Grid, Typography, useTheme } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import DisplayValue from './DisplayValue'

const questionMonitoringEmptyState = {
  companiesPlanToIntroduce: {
    number: 0,
    percentage: 0,
  },
  companiesHaveAnswered: {
    number: 0,
    percentage: 0,
  },
  companiesHaveNotAnswered: {
    number: 0,
    percentage: 0,
  },
}

interface QuestionCardProps {
  question: string
  children: JSX.Element
  questionMonitoring: QuestionMonitoring
  questionType: string
  sectionOrder?: number
  questionOrder?: number
  resultsText: string
  flagResult: FlagResult
}

const QuestionCard: React.FC<QuestionCardProps> = ({
  question,
  children,
  questionMonitoring,
  questionType,
  sectionOrder,
  questionOrder,
  resultsText,
  flagResult,
}) => {
  const { formatMessage } = useIntl()
  const { palette } = useTheme()

  const { companiesPlanToIntroduce, companiesHaveAnswered, companiesHaveNotAnswered } =
    questionMonitoring || questionMonitoringEmptyState

  return (
    <Box bgcolor={palette.background.default} borderRadius={2} p={5}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box display="flex">
            {sectionOrder && questionOrder && (
              <Typography mr={1} variant="h6">{`${sectionOrder}.${questionOrder}`}</Typography>
            )}
            <Typography variant="h6">
              <Markdown>{question}</Markdown>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={5}>
          <DisplayValue
            label={formatMessage({ id: 'statistics.companiesHaveIt' })}
            value={`${companiesHaveAnswered.percentage}%`}
            companiesNumber={companiesHaveAnswered.number}
            guidanceText={formatMessage({ id: 'statistics.companiesHaveItGuidance' })}
          />
        </Grid>
        <Grid item xs={4}>
          <DisplayValue
            label={formatMessage({ id: 'statistics.companiesDontHaveThis' })}
            value={`${companiesHaveNotAnswered.percentage}%`}
            companiesNumber={companiesHaveNotAnswered.number}
            textAlign="right"
            guidanceText={formatMessage({ id: 'statistics.companiesDontHaveThisGuidance' })}
          />
        </Grid>
        <Grid item xs={3}>
          <DisplayValue
            label={formatMessage({ id: 'statistics.companiesPlanToIntroduce' })}
            value={`${companiesPlanToIntroduce.percentage}%`}
            companiesNumber={companiesPlanToIntroduce.number}
            textAlign="right"
            guidanceText={formatMessage({ id: 'statistics.companiesPlanToIntroduceGuidance' })}
          />
        </Grid>
        <Grid item xs={12}>
          {questionMonitoring && questionType !== QuestionTypes.File && companiesHaveNotAnswered.percentage !== 100 && (
            <Box mb={1}>
              <Typography variant="subtitle1">{resultsText}</Typography>
            </Box>
          )}
          {questionType === QuestionTypes.File && companiesHaveNotAnswered.percentage !== 100 && (
            <Box mb={1} mt={3}>
              <Typography variant="subtitle1">{formatMessage({ id: 'statistics.expirationDates' })}</Typography>
            </Box>
          )}
          {Boolean(flagResult?.flaggedCount) && (
            <QuestionCardFlagIndicator flaggedCount={flagResult.flaggedCount} flagRules={flagResult.reportFlagRules} />
          )}
          {children}
        </Grid>
      </Grid>
    </Box>
  )
}

export default QuestionCard
