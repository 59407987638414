import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import LoadingButton from '@app/src/components/LoadingButton'
import React from 'react'
import { useIntl } from 'react-intl'

const useStyles = makeStyles(({ palette, typography }) => ({
  expandButton: {
    ...typography.body1,
    color: palette.text.secondary,
  },
}))

interface DetailsToggleProps {
  expanded: boolean
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>
  isLoading?: boolean
}

export const DetailsToggle: React.FC<DetailsToggleProps> = ({ expanded, setExpanded, isLoading }) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()
  return (
    <Box display="flex" justifyContent="center" width="100%" mt={2}>
      <LoadingButton
        endIcon={
          expanded && !isLoading ? (
            <ExpandLessIcon color="inherit" fontSize="small" />
          ) : (
            <ExpandMoreIcon color="inherit" fontSize="small" />
          )
        }
        disabled={isLoading}
        className={classes.expandButton}
        loading={isLoading}
        onClick={() => setExpanded(expanded => !expanded)}
      >
        {formatMessage({ id: expanded ? 'general.showLess' : 'statistics.showDetails' })}
      </LoadingButton>
    </Box>
  )
}
