import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchPost } from '@app/src/api/fetchHooks'
import { DetailsToggle } from '@app/src/pages/ResourceCollection/Collections/DataHub/QuestionTypeCharts/DetailsToggle'
import { OptionBar } from '@app/src/pages/ResourceCollection/Collections/DataHub/QuestionTypeCharts/Options/OptionBar'
import ProvidersByCategory from '@app/src/pages/ResourceCollection/Collections/DataHub/QuestionTypeCharts/ProvidersByCategory'
import { ProvidersForCategory } from '@app/src/pages/ResourceCollection/Collections/DataHub/QuestionTypeCharts/ProvidersForCategory'
import { MAX_OPTIONS_TO_SHOW } from '@app/src/pages/ResourceCollection/Collections/DataHub/QuestionTypeCharts/StatisticsConstants'
import { STATISTICS_SPECIAL_FILTERS } from '@app/src/pages/ResourceCollection/Filters/StatisticsConstants'
import { FilterGroup, Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { GenericOrganization } from '@app/src/types/organizations'
import { Collapse } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { OptionItemResult, OptionsResult } from '../../Reports/DataHubReport'

type OptionChartProps = {
  optionsResult: OptionsResult
  questionId: number
  filters: FilterGroup[]
  periodName: string
}

interface ProvidersByOption {
  optionValue: string
  providers: GenericOrganization[]
}

const OptionsChart: React.FC<OptionChartProps> = ({ optionsResult, questionId, periodName, filters }) => {
  const [expanded, setExpanded] = useState(false)
  const [hasBeenExpanded, setHasBeenExpanded] = useState(false)
  const [optionItems, setOptionItems] = useState<OptionItemResult[]>(() =>
    optionsResult.items.slice(0, MAX_OPTIONS_TO_SHOW),
  )
  const shouldDisplayProvidersBelowEachBar = optionsResult.items.length > MAX_OPTIONS_TO_SHOW

  const {
    data: providersByOptionArray,
    isLoading,
    isFetching,
  } = useFetchPost<ProvidersByOption[], FilterGroup[]>({
    payload: [
      ...filters.filter(fi => !STATISTICS_SPECIAL_FILTERS.includes(fi.name)),
      {
        name: 'requestItem.questionId',
        filters: [
          {
            value: questionId,
            operator: Operators.EqualTo,
          },
        ],
      },
      {
        name: 'response.request.periodname',
        filters: [
          {
            value: periodName,
            operator: Operators.EqualTo,
          },
        ],
      },
    ],
    endpoint: endpoints.providersByResponseItemOptions,
    key: [FetchKey.ProvidersByOption, questionId],
    options: { enabled: hasBeenExpanded },
  })

  useEffect(() => {
    setHasBeenExpanded(hasBeenExpanded => hasBeenExpanded || expanded)
  }, [expanded])

  const providersByOption = useMemo(() => {
    if (!providersByOptionArray) return new Map<string, GenericOrganization[]>()
    return new Map(providersByOptionArray.map(s => [s.optionValue.toLowerCase(), s.providers]))
  }, [isLoading || isFetching, providersByOptionArray?.length])

  useEffect(() => {
    if (!isLoading && expanded) {
      setOptionItems(optionsResult.items)
    }
  }, [expanded, isLoading])

  return (
    <>
      {optionItems.map(optionResult => (
        <React.Fragment key={optionResult.name}>
          <OptionBar
            key={optionResult.name}
            optionItemResult={optionResult}
            totalCount={optionsResult.totalCount}
            questionId={questionId}
            filters={filters}
          />
          {shouldDisplayProvidersBelowEachBar && (
            <Collapse in={expanded}>
              <ProvidersForCategory providers={providersByOption.get(optionResult.name.toLowerCase()) ?? []} />
            </Collapse>
          )}
        </React.Fragment>
      ))}

      {Boolean(optionsResult.items.length) && (
        <>
          {!shouldDisplayProvidersBelowEachBar && (
            <Collapse in={expanded}>
              <ProvidersByCategory
                categories={providersByOptionArray?.map(p => ({
                  providers: p.providers,
                  categoryText: p.optionValue,
                }))}
              />
            </Collapse>
          )}
          <DetailsToggle expanded={expanded} setExpanded={setExpanded} isLoading={isLoading} />
        </>
      )}
    </>
  )
}

export default OptionsChart
