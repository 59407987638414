import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchFacets } from '@app/src/api/fetchHooks'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import { useStringifyQueryFilters } from '@app/src/hooks/queryState'
import { StatisticsByTemplateSectionModel } from '@app/src/pages/ResourceCollection/Collections/DataHub/Reports/DataHubReport'
import ReportOverviewFlagPopover from '@app/src/pages/ResourceCollection/Collections/DataHub/Reports/ReportOverviewFlagPopover'
import {
  REPORT_TEMPLATE_ID_AND_PERIOD,
  filtersWithoutTemplateIdAndPeriod,
  getTemplateIdAndPeriodFromFilter,
  transformFilterNames,
} from '@app/src/pages/ResourceCollection/Filters/StatisticsConstants'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { InquiryStatus } from '@app/src/types/resourceExplorer'
import { WF_ORGANIZATION_ID } from '@app/src/wf-constants'
import paths from '@app/src/wf-constants/paths'
import { BarChart, ListAlt } from '@mui/icons-material'
import { Box, Button, ButtonGroup, Skeleton, Typography, alpha } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import React from 'react'
import { useIntl } from 'react-intl'
import { generatePath, useHistory } from 'react-router'
import { DataHubView, ViewType, tableViewAllowedFilters } from '../../DataHubScene'

type ReportOverviewProps = {
  viewType: ViewType
  statisticsSections: StatisticsByTemplateSectionModel[]
}

type CalculateFlagCount = {
  flaggedCount: number
  isWorldFavor: boolean
}

const useStyles = makeStyles(theme => ({
  active: {
    background: alpha(theme.palette.action.selected, theme.palette.action.selectedOpacity),
  },
}))

const ReportOverview: React.FC<ReportOverviewProps> = ({ viewType, statisticsSections }) => {
  const { formatMessage } = useIntl()
  const filters = useGetApiQueryFilters(tableViewAllowedFilters)
  const { stringifyQueryFilters } = useStringifyQueryFilters()
  const history = useHistory()
  const classes = useStyles()
  const cleanFilters = filtersWithoutTemplateIdAndPeriod(filters)
  const renamedFilters = transformFilterNames(cleanFilters)

  const { periodName, templateId } = getTemplateIdAndPeriodFromFilter(filters, REPORT_TEMPLATE_ID_AND_PERIOD)

  const { facets, isLoading } = useFetchFacets({
    key: FetchKey.InquiryStatus,
    endpoint: endpoints.inquiryWithFacets,
    facetsParam: [{ name: 'status', isEnum: true }],
    filter: [
      ...renamedFilters,
      {
        name: 'questionnaireTemplateId',
        filters: [
          {
            operator: Operators.EqualTo,
            value: templateId,
          },
        ],
      },
      periodName
        ? {
            name: 'periodName',
            filters: [
              {
                operator: Operators.EqualTo,
                value: periodName,
              },
            ],
          }
        : [],
    ].flat(),
    skipNotSet: true,
    options: {
      enabled: Boolean(templateId) && Boolean(periodName),
    },
  })

  const sentTo = facets
    .flat()
    .map(status => status?.count)
    .reduce((acc, curr) => acc + curr, 0)

  const replied = facets
    .flat()
    .filter(status => status?.value !== InquiryStatus.Requested)
    .map(status => status?.count)
    .reduce((acc, curr) => acc + curr, 0)

  const handleViewClick = (viewType: ViewType) => {
    history.push(
      stringifyQueryFilters({
        url: generatePath(paths.dataHubCollection, {
          view: DataHubView.Reports,
          viewType: viewType,
        }),
        queryParams: {},
        includeCurrentFilters: true,
      }),
    )
  }
  const computeFlagCount = (
    flagCount: number,
    statisticsSections: StatisticsByTemplateSectionModel[],
  ): CalculateFlagCount => {
    const isWorldFavor = statisticsSections.some(r =>
      r.items.some(i =>
        i.flagResult?.reportFlagRules?.some(flagRule => flagRule.creatorOrganizationId === WF_ORGANIZATION_ID),
      ),
    )
    return {
      flaggedCount: flagCount,
      isWorldFavor,
    }
  }
  const reducedStatistics = statisticsSections
    .flatMap(section => section.items)
    .reduce((total, item) => total + item.flagResult.flaggedCount, 0)

  const flagged = computeFlagCount(reducedStatistics, statisticsSections)

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      px={3}
      py={2}
      bgcolor="background.default"
      borderRadius={2}
    >
      <Box display="flex" flexDirection="column">
        <Box display="flex">
          <Box display="flex" flexDirection="column" mr={8}>
            <Typography mb={1} variant="overline">
              {formatMessage({ id: 'statistics.reportOverview.requests' })}
            </Typography>
            <Typography variant="h2">{isLoading ? <Skeleton variant="rectangular" /> : <>{sentTo}</>}</Typography>
            <Typography variant="body1">
              {formatMessage({ id: 'statistics.reportOverview.requestedCompanies' }, { count: sentTo })}
            </Typography>
          </Box>

          <Box display="flex" flexDirection="column" mr={8}>
            <Typography mb={1} variant="overline">
              {formatMessage({ id: 'statistics.reportOverview.responseRate' })}
            </Typography>
            <Typography variant="h2">
              {isLoading ? (
                <Skeleton variant="rectangular" />
              ) : (
                formatMessage(
                  { id: 'statistics.reportOverview.responseRateCount' },
                  { percentage: sentTo === 0 ? 0 : Math.round((replied / sentTo) * 100) },
                )
              )}
            </Typography>
            <Typography variant="body1">
              {formatMessage({ id: 'statistics.reportOverview.responseCompanies' }, { count: replied })}
            </Typography>
          </Box>

          {statisticsSections && Boolean(flagged.flaggedCount) && (
            <Box display="flex" flexDirection="column">
              <Typography mb={1} variant="overline">
                {formatMessage({ id: flagged.isWorldFavor ? 'statistics.suggestedRisks' : 'statistics.flaggedRisks' })}
              </Typography>
              <ReportOverviewFlagPopover statisticsSections={statisticsSections} flaggedCount={flagged.flaggedCount} />
            </Box>
          )}
        </Box>
      </Box>

      <ButtonGroup>
        <Button
          onClick={() => handleViewClick(ViewType.GRAPH)}
          startIcon={<BarChart />}
          className={classNames({ [classes.active]: viewType === ViewType.GRAPH })}
        >
          {formatMessage({ id: 'statistics.reportOverview.chartsButton' })}
        </Button>
        <Button
          onClick={() => handleViewClick(ViewType.TABLE)}
          startIcon={<ListAlt />}
          className={classNames({ [classes.active]: viewType === ViewType.TABLE })}
        >
          {formatMessage({ id: 'statistics.reportOverview.detailsButton' })}
        </Button>
      </ButtonGroup>
    </Box>
  )
}

export default ReportOverview
