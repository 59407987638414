import { DetailsToggle } from '@app/src/pages/ResourceCollection/Collections/DataHub/QuestionTypeCharts/DetailsToggle'
import { Box, CircularProgress, Collapse, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

interface DetailsSectionProps {
  children: JSX.Element
  isLoading: boolean
  expanded: boolean
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>
}

const DetailsSection: React.FC<DetailsSectionProps> = ({ children, isLoading, expanded, setExpanded }) => {
  const { formatMessage } = useIntl()

  return (
    <Box>
      <Collapse in={expanded}>
        <Box>
          <Typography variant="h5" gutterBottom>
            {formatMessage({ id: 'statistics.resultDetails' })}
          </Typography>
          <Box>
            {isLoading ? (
              <Box height={100} display="flex" justifyContent="center" alignItems="center">
                <CircularProgress size={24} />
              </Box>
            ) : (
              children
            )}
          </Box>
        </Box>
      </Collapse>
      <DetailsToggle expanded={expanded} setExpanded={setExpanded} />
    </Box>
  )
}

export default DetailsSection
