import { useStringifyQueryFilters } from '@app/src/hooks/queryState'
import { ResponseItemStatisticsByTemplateModel } from '@app/src/pages/ResourceCollection/Collections/DataHub/Reports/DataHubReport'
import { DataHubView } from '@app/src/pages/ResourceCollection/Collections/DataHubScene'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import paths from '@app/src/wf-constants/paths'
import { Box, Stack, Typography, useTheme } from '@mui/material'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import * as echarts from 'echarts/core'
import React, { useEffect, useMemo } from 'react'
import { generatePath, useHistory } from 'react-router'

interface PieChartProps {
  pieChartData: ResponseItemStatisticsByTemplateModel
  organizationId?: number
}

interface OptionsResultData {
  name: string
  percentage: number
  count: number
}

interface OptionsResultItemData {
  name: string
  value: number
  count?: number
}

const MAX_SLICES = 10

function filterZeroValue(data: OptionsResultItemData[] | undefined) {
  return data?.filter(item => item.value !== 0)
}

const PieChart = ({ pieChartData, organizationId }: PieChartProps) => {
  const { palette } = useTheme()
  const eChartsRef = React.useRef<null | ReactEChartsCore>(null)
  const { periodName, id, questionMonitoring } = pieChartData
  const { stringifyQueryFilters } = useStringifyQueryFilters()
  const history = useHistory()

  const rawData = pieChartData?.optionsResult?.items?.map((item: OptionsResultData) => ({
    value: item.percentage,
    count: item.count,
    name: item.name,
  }))

  const slicedData = filterZeroValue(rawData)?.slice(0, MAX_SLICES)

  const totalValue = rawData?.reduce((acc: number, item: OptionsResultItemData) => acc + item.value, 0) ?? 0
  const othersValue =
    totalValue - (slicedData?.reduce((acc: number, item: OptionsResultItemData) => acc + item.value, 0) ?? 0)

  if (rawData?.length && rawData.length > 10 && slicedData) {
    slicedData.push({ value: othersValue, name: 'Others' })
  }

  const answerLink = (answer: string | number) => {
    const filters = [
      {
        name: 'response.request.periodName',
        value: [periodName],
        operator: Operators.In,
      },
      {
        name: 'requestItem.template.id',
        value: [id],
        operator: Operators.In,
      },
      {
        name: 'answer',
        value: answer,
        operator: Operators.In,
      },
    ]

    return stringifyQueryFilters({
      url: generatePath(paths.dataHubCollection, {
        view: DataHubView.Answers,
      }),
      queryParams: {
        filters,
      },
    })
  }

  const othersAnswerLink = () => {
    const filters = [
      {
        name: 'response.request.periodName',
        value: [periodName],
        operator: Operators.In,
      },
      {
        name: 'requestItem.template.id',
        value: [id],
        operator: Operators.In,
      },
    ]

    return stringifyQueryFilters({
      url: generatePath(paths.dataHubCollection, {
        view: DataHubView.Answers,
      }),
      queryParams: {
        filters,
      },
    })
  }

  const pieChartColors = [
    palette.visualization[1],
    palette.visualization[2],
    palette.visualization[3],
    palette.visualization[4],
    palette.visualization[5],
    palette.visualization[6],
    palette.visualization[7],
    palette.visualization[8],
    palette.visualization[9],
    palette.visualization[10],
  ]

  const pieChartOptions = {
    tooltip: {
      trigger: 'item',
      formatter: function (slicedData: { data: { count: number; value: number; name: string } }) {
        return `${slicedData.data.name}`
      },
    },
    grid: {
      left: '0%',
      right: '0%',
      top: '0%',
      bottom: '0%',
    },
    color: pieChartColors,
    series: [
      {
        type: 'pie',
        radius: '60%',
        data: slicedData,
        itemStyle: {
          borderColor: 'white',
          borderWidth: 2,
          emphasis: {
            borderColor: 'white',
            borderWidth: 2,
          },
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 2,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.1)',
          },
        },
        label: {
          formatter: function (params: { name: string }) {
            const label = params.name
            const maxCharactersPerLine = 10
            const words = label.split(' ')
            const lines = []
            let currentLine = ''

            for (const word of words) {
              if ((currentLine + word).length <= maxCharactersPerLine) {
                currentLine += word + ' '
              } else {
                lines.push(currentLine.trim())
                currentLine = word + ' '
              }
            }

            if (currentLine !== '') {
              lines.push(currentLine.trim())
            }

            return lines.join('\n')
          },
        },
      },
    ],
  }
  const isTransparencyProviderProgressView = !organizationId

  const events = useMemo(
    () => ({
      click: ({ data }: { data: OptionsResultItemData }) => {
        if (isTransparencyProviderProgressView) return
        if (data.name === 'Others') {
          history.push(othersAnswerLink())
        } else {
          history.push(answerLink(data.name))
        }
      },
    }),
    [],
  )

  useEffect(() => {
    if (eChartsRef && eChartsRef.current) {
      const instance = eChartsRef.current.getEchartsInstance()
      instance.resize()
    }
  }, [pieChartData])

  return (
    <>
      {questionMonitoring?.companiesHaveAnswered?.number ? (
        <Box width="100%" display="flex" flexDirection="column" alignItems="center" overflow="hidden">
          <ReactEChartsCore
            option={pieChartOptions}
            echarts={echarts}
            onEvents={events}
            style={{ width: '100%' }}
            ref={eChartsRef}
          />
          <Stack mt={1} spacing={0.5} textAlign="center">
            <Typography variant="subtitle2" color="textSecondary">
              {periodName}
            </Typography>
          </Stack>
        </Box>
      ) : (
        <></>
      )}
    </>
  )
}

export default PieChart
