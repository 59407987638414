import { useDisplayValueWithUnit } from '@app/src/hooks/useDisplayValueWithUnit'
import {
  NumberMetricSelector,
  SelectedMetric,
} from '@app/src/pages/ResourceCollection/Collections/DataHub/DataInsights/NumberMetricSelector'
import { StatisticsForQuestionPerPeriod } from '@app/src/pages/ResourceCollection/Collections/DataHub/Reports/DataHubReport'
import { palette } from '@app/src/theme/palette'
import { Unit } from '@app/src/types/resourceExplorer'
import {
  getMinMaxOnYAxisNumber,
  getUnitDisplayText,
  numberToString,
  numberToStringWithUnit,
  shouldShowSum,
} from '@app/src/utils/statisticsUtils'
import { Box } from '@mui/material'
import { useTheme } from '@mui/styles'
import { EChartsOption } from 'echarts'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import * as echarts from 'echarts/core'
import { LineDataItemOption } from 'echarts/types/src/chart/line/LineSeries'
import React, { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDataInsightsModal } from './DataInsightsModalContext'
import { EchartClickItemData } from './OptionMultiPeriodChart'

interface NumberMultiPeriodLineChartProps {
  statistics: StatisticsForQuestionPerPeriod
  height: string
  echartsRef: React.MutableRefObject<ReactEChartsCore | null>
  unit: Unit
}

export const NumberMultiPeriodLineChart: React.FC<NumberMultiPeriodLineChartProps> = ({
  statistics,
  height,
  echartsRef,
  unit,
}) => {
  const { typography } = useTheme()
  const { getOptimalUnitForMultipleValues } = useDisplayValueWithUnit()
  const { formatMessage } = useIntl()
  const showSum = shouldShowSum(unit)
  const [selectedMetric, setSelectedMetric] = useState<SelectedMetric>(
    showSum ? SelectedMetric.Sum : SelectedMetric.Average,
  )

  const { openDataInsightsModal } = useDataInsightsModal()

  const events = useMemo(
    () => ({
      click: ({ data }: { data: EchartClickItemData }) => {
        const userSelection = {
          period: data.name,
        }
        openDataInsightsModal(statistics, userSelection)
      },
    }),
    [openDataInsightsModal, statistics],
  )

  const questionTitle = statistics.title
  const resultsForPeriod = statistics.resultForPeriods

  const options: EChartsOption = useMemo(() => {
    const data: LineDataItemOption[] = resultsForPeriod.map(r => ({
      name: r.period,
      value: selectedMetric === SelectedMetric.Average ? r.numberResult?.average : r.numberResult?.sum,
    }))

    const values = data.flatMap(d => (Number(d.value) ? Number(d.value) : []))
    const optimalUnit = getOptimalUnitForMultipleValues(values, unit)
    const { min, max } = getMinMaxOnYAxisNumber(values, selectedMetric, optimalUnit)

    const existingOptions = resultsForPeriod.map(f => f.period)

    return {
      tooltip: {
        valueFormatter: value => numberToStringWithUnit(Number(value), unit, optimalUnit),
      },
      legend: {
        icon: 'circle',
        bottom: 10,
        left: 0,
        selectedMode: false,
        textStyle: {
          fontFamily: typography.fontFamily,
          fontSize: typography.body2.fontSize,
        },
      },
      color: palette.visualization['5'],
      grid: {
        containLabel: true,
        left: 30,
        right: 7,
        top: 30,
        bottom: 50,
      },
      xAxis: {
        type: 'category',
        data: existingOptions,
        axisLabel: {
          interval: 0,
          fontFamily: typography.fontFamily,
          fontSize: typography.body2.fontSize,
        },
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          formatter: value => numberToString(value, unit, optimalUnit),
        },
        name: getUnitDisplayText(optimalUnit, formatMessage({ id: 'statistics.dataInsights.count' })),
        nameLocation: 'middle',
        nameGap: 35,
        nameTextStyle: {
          fontFamily: typography.fontFamily,
          fontSize: typography.body2.fontSize,
        },
        min: min,
        max: max,
      },
      series: {
        name: getUnitDisplayText(optimalUnit, questionTitle),
        type: 'line',
        label: {
          show: true,
          position: 'top',
          color: 'black',
          formatter: value => numberToStringWithUnit(Number(value.value), unit, optimalUnit),
          fontFamily: typography.fontFamily,
          fontSize: typography.body2.fontSize,
        },
        areaStyle: {
          color: palette.visualization['11'],
          opacity: 0.5,
        },
        data: data,
      },
    }
  }, [
    resultsForPeriod,
    getOptimalUnitForMultipleValues,
    unit,
    typography.fontFamily,
    typography.body2.fontSize,
    formatMessage,
    questionTitle,
    selectedMetric,
  ])

  return (
    <Box height={height} display="flex" flexDirection="column">
      <NumberMetricSelector selectedMetric={selectedMetric} setSelectedMetric={setSelectedMetric} showSum={showSum} />
      <ReactEChartsCore
        onEvents={events}
        option={options}
        echarts={echarts}
        style={{ minWidth: '100%', flexGrow: 1 }}
        ref={echartsRef}
      />
    </Box>
  )
}
