import TruncatedText from '@app/src/components/Ui/TruncatedText'
import DataHubInsightsCardMenu from '@app/src/pages/ResourceCollection/Collections/DataHub/DataInsights/DataHubInsightsCardMenu'
import { StatisticsForQuestionPerPeriod } from '@app/src/pages/ResourceCollection/Collections/DataHub/Reports/DataHubReport'
import { Box, ButtonBase, Paper, Typography } from '@mui/material'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import React from 'react'

interface DataHubInsightsCardProps {
  children: React.ReactNode
  statistics: StatisticsForQuestionPerPeriod
  eChartsRef: React.MutableRefObject<ReactEChartsCore | null>
  onClickTitle: () => void
  chartSelector: React.ReactNode
}

const DataHubCard: React.FC<DataHubInsightsCardProps> = ({
  children,
  statistics,
  eChartsRef,
  onClickTitle,
  chartSelector,
}) => {
  return (
    <Paper
      elevation={0}
      variant="outlined"
      sx={({ palette }) => ({ padding: 2, outline: `1px solid ${palette.grey[300]}`, borderRadius: 2 })}
    >
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="start">
          <Box>
            <Box component={ButtonBase} onClick={onClickTitle}>
              <TruncatedText text={statistics.title} line={1} variant="subtitle1" />
            </Box>
            {statistics.resultForPeriods.length === 1 && (
              <Typography variant="body1" color="text.secondary">
                {statistics.resultForPeriods[0].period}
              </Typography>
            )}
          </Box>
        </Box>
        <Box display="flex" alignItems="start" gap={1}>
          {chartSelector}
          <DataHubInsightsCardMenu echartsRef={eChartsRef} title={statistics.title} />
        </Box>
      </Box>
      {children}
    </Paper>
  )
}

export default DataHubCard
