import { Box, Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import QuestionCard from '../QuestionCard'
import DataHubCharts from '../QuestionTypeCharts/DataHubCharts'
import { StatisticsByTemplateSectionModel } from './DataHubReport'

import { MAX_OPTIONS_TO_SHOW } from '@app/src/pages/ResourceCollection/Collections/DataHub/QuestionTypeCharts/StatisticsConstants'
import { FilterGroup } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { useIntl } from 'react-intl'

type ReportGraphsProps = {
  filters: FilterGroup[]
  sections: StatisticsByTemplateSectionModel[]
}

const useStyles = makeStyles(({ spacing, shape }) => ({
  container: {
    paddingTop: spacing(4),
    paddingBottom: spacing(4),
    borderRadius: shape.borderRadius,
    justifyContent: 'center',
  },
}))

const ReportGraphs: React.FC<ReportGraphsProps> = ({ filters, sections }) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()

  return (
    <Grid container className={classes.container}>
      <Grid item lg={6} md={8} xs={10}>
        <Grid container spacing={4}>
          {sections.map(section => (
            <Grid xs={12} item key={section.order}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Box display="flex">
                    <Typography variant="h3" mr={1}>
                      {section.order}.
                    </Typography>
                    <Box>
                      <Typography variant="h3">{section.title}</Typography>
                      {section.description && <Typography variant="body1">{section.description}</Typography>}
                    </Box>
                  </Box>
                </Grid>
                {section.items.map((item, i) => (
                  <Grid item xs={12} key={item.id}>
                    <QuestionCard
                      sectionOrder={section.order}
                      questionOrder={i + 1}
                      question={item.title}
                      questionMonitoring={item.questionMonitoring}
                      questionType={item.questionType}
                      resultsText={formatMessage({
                        id: `statistics.${
                          item.optionsResult?.items.length > MAX_OPTIONS_TO_SHOW ? 'mostAnswered' : 'results'
                        }`,
                      })}
                      flagResult={item.flagResult}
                    >
                      <DataHubCharts
                        numberResult={item.numberResult}
                        questionType={item.questionType}
                        unit={item.unit}
                        filters={filters}
                        questionId={item.id}
                        optionsResult={item.optionsResult}
                        fileResult={item.fileResult}
                        periodName={item.periodName}
                        companiesHaveAnswered={item.questionMonitoring?.companiesHaveAnswered?.number ?? 0}
                      />
                    </QuestionCard>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ReportGraphs
