import {
  ResultForPeriod,
  StatisticsForQuestionPerPeriod,
} from '@app/src/pages/ResourceCollection/Collections/DataHub/Reports/DataHubReport'
import { palette } from '@app/src/theme/palette'
import { getVisualizationColorsMaxContrast } from '@app/src/utils/statisticsUtils'
import { darken } from '@mui/material'

import { EChartsOption, PieSeriesOption } from 'echarts'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import { PieChart } from 'echarts/charts'
import { GridComponent, LegendComponent, TitleComponent, TooltipComponent } from 'echarts/components'
import * as echarts from 'echarts/core'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useDataInsightsModal } from './DataInsightsModalContext'
import { EchartClickItemData } from './OptionMultiPeriodChart'

interface OptionBarProps {
  statistics: StatisticsForQuestionPerPeriod
  height: string
  echartsRef: React.MutableRefObject<ReactEChartsCore | null>
}

export const OptionSinglePeriodPieChart: React.FC<OptionBarProps> = ({ statistics, height, echartsRef }) => {
  const { formatMessage } = useIntl()

  const { openDataInsightsModal } = useDataInsightsModal()

  const resultsForPeriod: ResultForPeriod = statistics.resultForPeriods[0]
  const events = useMemo(
    () => ({
      click: ({ data }: { data: EchartClickItemData }) => {
        const userSelection = {
          period: resultsForPeriod.period,
          answer: data.answersSelected,
        }
        openDataInsightsModal(statistics, userSelection)
      },
    }),
    [openDataInsightsModal, resultsForPeriod.period, statistics],
  )

  echarts.use([TitleComponent, TooltipComponent, GridComponent, PieChart, LegendComponent])
  const options: EChartsOption = useMemo(() => {
    const maxNumberOfOptionsToShow = 10
    const optionStatisticsView =
      resultsForPeriod?.optionStatisticsViews?.find(op => op.maxItemCount === maxNumberOfOptionsToShow) ??
      resultsForPeriod.optionStatisticsViews?.[0]
    const itemsInView = optionStatisticsView?.items ?? []
    const colors =
      itemsInView.length === 1 ? [palette.visualization[1]] : getVisualizationColorsMaxContrast(itemsInView.length)

    const data: PieSeriesOption[] =
      itemsInView.map((op, index) => ({
        value: op.count,
        name: op.name,
        answersSelected: op.isOther ? optionStatisticsView?.valuesInOther ?? [] : [op.name],
        emphasis: { itemStyle: { color: darken(colors[index], 0.2) }, scale: false },
        label: {
          show: op.count > 0,
          formatter: `{marker|●} {b} ({c})`,
          rich: {
            marker: {
              color: colors[index],
              fontSize: 28,
            },
          },
        },
      })) ?? []

    return {
      tooltip: {
        trigger: 'item',
        confine: true,
      },
      color: colors,
      series: [
        {
          type: 'pie',
          data: data,
          itemStyle: {
            borderColor: '#ffffff',
            borderWidth: 3,
          },
        },
      ],
    }
  }, [formatMessage, resultsForPeriod.optionStatisticsViews, resultsForPeriod.optionStatisticsViews])

  return (
    <ReactEChartsCore
      onEvents={events}
      option={options}
      echarts={echarts}
      style={{ minWidth: '100%', height }}
      ref={echartsRef}
    />
  )
}
