import { Box, Button } from '@mui/material'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewFlagRule from '@app/src/components/Drawer/Views/DrawerViewFlagRule'
import FlagSuggestedRiskAvatar from '@app/src/components/Flags/FlagSuggestedRiskAvatar'
import { AvatarSize } from '@app/src/components/Ui/Avatar'
import React from 'react'
import { useIntl } from 'react-intl'
import { ReportFlagRule } from '@app/src/types/flags'

interface WfQuestionFlagIndicatorProps {
  flaggedCount: number
  flagRules?: ReportFlagRule[]
}

const WfQuestionFlagIndicator: React.FC<WfQuestionFlagIndicatorProps> = ({ flaggedCount, flagRules }) => {
  const { openDrawer } = useDrawer()
  const { formatMessage } = useIntl()

  return (
    <Box
      bgcolor={({ palette }) => palette.communication.light}
      width="100%"
      px={2}
      py={1}
      display="flex"
      alignItems="center"
      borderRadius={0.5}
      mb={5}
    >
      <FlagSuggestedRiskAvatar size={AvatarSize.Small} />
      <Box flex={1} ml={1}>
        {formatMessage(
          { id: 'statistics.answersReportedAsSuggestedRisk' },
          {
            flagCount: flaggedCount,
          },
        )}
      </Box>

      {Boolean(flagRules?.length) && (
        <Button variant="text" onClick={() => openDrawer(<DrawerViewFlagRule flagRules={flagRules} />)}>
          {formatMessage({ id: 'statistics.showDetails' })}
        </Button>
      )}
    </Box>
  )
}

export default WfQuestionFlagIndicator
