import { ChartType } from '@app/src/pages/ResourceCollection/Collections/Assessments/ChartTypeSelector'
import DataHubCard from '@app/src/pages/ResourceCollection/Collections/DataHub/DataInsights/DataHubCard'
import DataHubChartTypeSelector from '@app/src/pages/ResourceCollection/Collections/DataHub/DataInsights/DataHubChartTypeSelector'
import { StatisticsForQuestionPerPeriod } from '@app/src/pages/ResourceCollection/Collections/DataHub/Reports/DataHubReport'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import React, { useEffect, useState } from 'react'
import DataInsightsChart from './DataHubInsightsChart'
import { useDataInsightsModal } from './DataInsightsModalContext'

interface DataHubInsightsCardProps {
  statistics: StatisticsForQuestionPerPeriod
  index: number
}

const DataHubInsightsCard: React.FC<DataHubInsightsCardProps> = ({ statistics, index }) => {
  const eChartsRef = React.useRef<null | ReactEChartsCore>(null)
  const [selectedChartType, setSelectedChartType] = useState<ChartType>(ChartType.Vertical)
  const { openDataInsightsModal, setDataInsightsRefForIndex } = useDataInsightsModal()

  useEffect(() => {
    setDataInsightsRefForIndex(eChartsRef, index)
  }, [index, setDataInsightsRefForIndex])

  return (
    <DataHubCard
      statistics={statistics}
      eChartsRef={eChartsRef}
      onClickTitle={() => openDataInsightsModal(statistics)}
      chartSelector={
        <DataHubChartTypeSelector
          selectedChartType={selectedChartType}
          setSelectedChartType={setSelectedChartType}
          statistics={statistics}
        />
      }
    >
      <DataInsightsChart statistics={statistics} eChartsRef={eChartsRef} selectedChartType={selectedChartType} />
    </DataHubCard>
  )
}

export default DataHubInsightsCard
