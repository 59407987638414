import { StringifyFilterValue, useStringifyQueryFilters } from '@app/src/hooks/queryState'
import { OptionItemResult } from '@app/src/pages/ResourceCollection/Collections/DataHub/Reports/DataHubReport'
import { DataHubView } from '@app/src/pages/ResourceCollection/Collections/DataHubScene'
import { FilterGroup, Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import paths from '@app/src/wf-constants/paths'
import { useTheme } from '@mui/material'
import { EChartsOption } from 'echarts'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import { BarChart } from 'echarts/charts'
import { GridComponent, LegendComponent, TitleComponent, TooltipComponent } from 'echarts/components'
import * as echarts from 'echarts/core'
import { SVGRenderer } from 'echarts/renderers'
import { TopLevelFormatterParams } from 'echarts/types/src/component/tooltip/TooltipModel'
import { LabelOption } from 'echarts/types/src/util/types'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { generatePath, useHistory } from 'react-router'
import { OptionLabel } from './OptionLabel'

type GraphDataItem = {
  value: number
  filters: StringifyFilterValue[]
  percentage: number
  name: string
}

interface OptionBarProps {
  optionItemResult: OptionItemResult
  totalCount: number
  questionId: number
  filters: FilterGroup[]
}

export const OptionBar: React.FC<OptionBarProps> = ({ optionItemResult, totalCount, questionId, filters }) => {
  const chartRef = useRef<HTMLDivElement>(null)
  const [width, setWidth] = useState<number>()
  const { palette, typography } = useTheme()
  const history = useHistory()
  const { stringifyQueryFilters } = useStringifyQueryFilters()
  echarts.use([TitleComponent, TooltipComponent, GridComponent, BarChart, SVGRenderer, LegendComponent])

  const labels = [optionItemResult.name]
  const values = [
    {
      value: optionItemResult.count,
      filters: [
        ...filters,
        {
          name: 'answer',
          value: optionItemResult.name,
          operator: Operators.EqualTo,
        },
        {
          name: 'requestItem.template.id',
          value: questionId,
          operator: Operators.EqualTo,
        },
      ],
      itemStyle: { borderRadius: optionItemResult.percentage !== 100 ? [4, 0, 0, 4] : [4, 4, 4, 4] },
      percentage: optionItemResult.percentage,
    },
  ]

  const options: EChartsOption = useMemo(
    () => ({
      tooltip: {
        extraCssText: 'width:50%; white-space:pre-wrap; text-align: center;',
        show: true,
        trigger: 'axis',
        axisPointer: { type: 'none' },
        position: 'inside',
        confine: true,
        formatter: (formattingItem: TopLevelFormatterParams) => {
          const { name, data } = Array.isArray(formattingItem) ? formattingItem[0] : formattingItem
          return `${name} <b>${(data as GraphDataItem).value}<b/>`
        },
      },
      textStyle: {
        fontFamily: typography.fontFamily,
        fontWeight: 500,
        color: palette.text?.primary,
      },
      title: {
        show: false,
      },
      legend: { show: false },
      xAxis: {
        max: totalCount,
        show: true,
        position: 'top',
        splitLine: {
          show: false,
        },
        triggerEvent: true,
      },
      yAxis: {
        inverse: true,
        type: 'category',
        data: labels,
        splitLine: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        triggerEvent: true,
        axisLabel: {
          show: false,
        } as LabelOption,
      },
      grid: {
        top: 0,
        bottom: 0,
        right: 0,
        containLabel: false,
        show: false,
        left: 0,
      },
      series: [
        {
          barMinWidth: 24,
          type: 'bar',
          stack: 'chart',
          barCategoryGap: '40%',
          barWidth: 32,
          data: values,
          label: {
            lineHeight: 16,
            position: 'insideRight',
            verticalAlign: 'middle',
            formatter: (formattingItem: TopLevelFormatterParams): string => {
              const { data } = Array.isArray(formattingItem) ? formattingItem[0] : formattingItem
              return `${(data as GraphDataItem).percentage}%`
            },
            show: true,
            color: palette.common.white,
          },
          itemStyle: {
            color: palette.secondary.main,
            borderType: 'solid',
          },
        },
        {
          type: 'bar',
          stack: 'chart',
          data: values.map(v => ({
            value: totalCount - v.value,
            filters: v.filters,
            percentage: v.percentage,
          })),
          itemStyle: {
            color: palette.grey['50'],
            borderRadius: [0, 8, 8, 0],
            borderType: 'solid',
          },
        },
      ],
    }),
    [values],
  )

  const events = useMemo(
    () => ({
      click: ({ data }: { data: GraphDataItem }) => {
        if (data) {
          history.push(
            stringifyQueryFilters({
              url: generatePath(paths.dataHubCollection, {
                view: DataHubView.Answers,
              }),
              queryParams: {
                filters: data.filters,
              },
              includeCurrentFilters: true,
            }),
          )
        }
      },
    }),
    [],
  )

  useEffect(() => {
    if (chartRef.current) {
      setWidth(chartRef.current?.offsetWidth)
      window.addEventListener('resize', () => {
        setWidth(chartRef.current?.offsetWidth)
      })
    }
    return () => {
      window.removeEventListener('resize', () => {
        setWidth(chartRef.current?.offsetWidth)
      })
    }
  }, [chartRef.current])

  return (
    <div ref={chartRef}>
      <OptionLabel labels={labels} companyCount={values.map(x => x.value)} />
      <ReactEChartsCore
        opts={{ width: width }}
        onEvents={events}
        option={options}
        echarts={echarts}
        style={{ minWidth: '100%', height: 90 }}
      />
    </div>
  )
}
