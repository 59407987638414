import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import DataInsights from '@app/src/pages/ResourceCollection/Collections/DataHub/DataInsights'
import { Flows } from '@app/src/wf-constants'
import paths from '@app/src/wf-constants/paths'
import React from 'react'
import { useIntl } from 'react-intl'
import { generatePath, Redirect, Route, Switch, useParams } from 'react-router'
import { ITEM_TEMPLATE_ID_AND_PERIOD, REPORT_TEMPLATE_ID_AND_PERIOD } from '../Filters/StatisticsConstants'
import { FilterGroup } from '../Filters/useFilters'
import DataHubAnswersTable from './DataHub/AllAnswers/DataHubAnswersTable'
import Questions from './DataHub/Questions'
import Reports from './DataHub/Reports'

export type DataHubSceneParams = {
  flow: string
  view: string
  viewType: ViewType
}

type DataHubSceneProps = {
  relatedContentFilter?: FilterGroup[]
  embedded?: boolean
}

export enum ViewType {
  GRAPH = 'graph',
  TABLE = 'table',
}

export enum DataHubView {
  Questions = 'questions',
  Reports = 'reports',
  Answers = 'answers',
  Overview = 'overview',
  DataInsights = 'data-insights',
}

export const tableViewAllowedFilters = [
  'requestItem.template.id',
  'response.request.periodName',
  'response.request.subscriptions.target.country.id',
  'response.request.subscriptions.target.organization.id',
  'response.request.template.id',
  'requestItem.section.template.id',
  'response.submittedAt',
  'cannotAnswer',
  'answer',
  'response.request.subscriptions.target.providerApprovalStatus',
  'response.request.subscriptions.target.priority',
  'response.request.subscriptions.target.finalRiskRating',
  'response.request.subscriptions.target.activityStatus',
  'response.request.subscriptions.target.supplierUsage',
  'response.request.subscriptions.target.tier',
  'response.request.subscriptions.target.ownerUserId',
  'response.request.subscriptions.target.categoryOptions.id',
  ITEM_TEMPLATE_ID_AND_PERIOD,
  'response.request.template.tags.tag',
  REPORT_TEMPLATE_ID_AND_PERIOD,
  'response.verifications.externalVerificationStatus',
  'response.verifications.internalVerificationStatus',
  'response.request.subscriptions.target.mappingNodes.actorTypeModel.id',
  'response.request.subscriptions.target.mappingNodes.tier',
  'fileScreeningResults.result',
  'answerStatus',
]

const DataHubScene: React.FC<DataHubSceneProps> = ({ relatedContentFilter = [], embedded }) => {
  const { formatMessage } = useIntl()
  const { flow = Flows.Interaction, view, viewType } = useParams<DataHubSceneParams>()
  const userFilters = useGetApiQueryFilters(tableViewAllowedFilters)

  const actionTabs = [
    {
      type: DataHubView.DataInsights,
      url: generatePath(paths.dataHubCollection, { view: DataHubView.DataInsights }),
      label: formatMessage({ id: 'dataHub.dataInsights' }),
    },
    {
      type: DataHubView.Reports,
      url: generatePath(paths.dataHubCollection, { view: DataHubView.Reports, viewType: ViewType.GRAPH }),
      label: formatMessage({ id: 'dataHub.reports' }),
    },
    {
      type: DataHubView.Questions,
      url: generatePath(paths.dataHubCollection, { view: DataHubView.Questions }),
      label: formatMessage({ id: 'dataHub.questions' }),
    },
    {
      type: DataHubView.Answers,
      url: generatePath(paths.dataHubCollection, { view: DataHubView.Answers }),
      label: formatMessage({ id: 'dataHub.answers' }),
    },
  ]

  if (embedded) {
    return (
      <DataHubAnswersTable
        relatedContentFilter={relatedContentFilter}
        allowedFilters={tableViewAllowedFilters}
        userFilters={userFilters}
        embedded={embedded}
        showCompanyCell={view === DataHubView.Answers || view === DataHubView.DataInsights}
      />
    )
  }

  if (!view) {
    return (
      <Redirect
        to={generatePath(paths.dataHubCollection, {
          view: DataHubView.Reports,
          viewType: ViewType.GRAPH,
        })}
      />
    )
  }

  return (
    <Switch>
      <Route
        exact
        path={generatePath(paths.dataHubCollection, {
          flow,
          view: DataHubView.DataInsights,
        })}
      >
        <DataInsights tabs={{ actionTabs, activeTabParam: view }} />
      </Route>
      <Route
        path={generatePath(paths.dataHubCollection, {
          flow,
          view: DataHubView.Reports,
        })}
      >
        <Reports
          tabs={{ actionTabs, activeTabParam: view }}
          allowedFilters={tableViewAllowedFilters}
          relatedContentFilter={relatedContentFilter}
          userFilters={userFilters}
          viewType={viewType}
        />
      </Route>

      <Route
        exact
        path={generatePath(paths.dataHubCollection, {
          flow,
          view: DataHubView.Questions,
        })}
      >
        <Questions
          tabs={{ actionTabs, activeTabParam: view }}
          allowedFilters={tableViewAllowedFilters}
          relatedContentFilter={relatedContentFilter}
          userFilters={userFilters}
        />
      </Route>

      <Route
        exact
        path={generatePath(paths.dataHubCollection, {
          flow,
          view: DataHubView.Answers,
        })}
      >
        <DataHubAnswersTable
          tabs={{ actionTabs, activeTabParam: view }}
          relatedContentFilter={relatedContentFilter}
          allowedFilters={tableViewAllowedFilters}
          userFilters={userFilters}
          showCompanyCell={view === DataHubView.Answers || view === DataHubView.DataInsights}
        />
      </Route>
    </Switch>
  )
}

export default DataHubScene
