import DataHubFiltersReports from '@app/src/pages/ResourceCollection/Filters/DataHubFiltersReports'
import { FilterGroup } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import ResourceCollectionScene, {
  ResourceCollectionSceneProps,
} from '@app/src/pages/ResourceCollection/ResourceCollectionScene'
import paths from '@app/src/wf-constants/paths'
import React from 'react'
import { useIntl } from 'react-intl'
import { Redirect, generatePath } from 'react-router'
import { DataHubView, ViewType } from '../../DataHubScene'
import DataHubReport from './DataHubReport'

type ReportsProps = {
  tabs: ResourceCollectionSceneProps['tabs']
  allowedFilters: string[]
  relatedContentFilter?: FilterGroup[]
  userFilters: FilterGroup[]
  viewType: ViewType
}

const Reports: React.FC<ReportsProps> = ({
  tabs,
  allowedFilters,
  relatedContentFilter = [],
  userFilters,
  viewType,
}) => {
  const { formatMessage } = useIntl()

  if (!viewType) {
    return (
      <Redirect
        to={generatePath(paths.dataHubCollection, {
          view: DataHubView.Reports,
          viewType: ViewType.GRAPH,
        })}
      />
    )
  }

  return (
    <ResourceCollectionScene
      title={formatMessage({
        id: 'schemas.responseItem.responseItems',
      })}
      tabs={tabs}
      filter={<DataHubFiltersReports implicitFilters={relatedContentFilter} allowedFilters={allowedFilters} />}
      enableScroll={viewType === ViewType.GRAPH}
    >
      <DataHubReport filters={userFilters} viewType={viewType} />
    </ResourceCollectionScene>
  )
}

export default Reports
