import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchPost } from '@app/src/api/fetchHooks'
import DetailsSection from '@app/src/pages/ResourceCollection/Collections/DataHub/QuestionTypeCharts/DetailsSection'
import ProvidersByCategory, {
  Category,
} from '@app/src/pages/ResourceCollection/Collections/DataHub/QuestionTypeCharts/ProvidersByCategory'
import { STATISTICS_SPECIAL_FILTERS } from '@app/src/pages/ResourceCollection/Filters/StatisticsConstants'
import { FilterGroup, Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { ProvidersExpirationDate } from './FilesChart'

interface ProvidersExpirationDateProps {
  questionId: number
  filters: FilterGroup[]
  periodName: string
}

const ProvidersByFileExpiration: React.FC<ProvidersExpirationDateProps> = ({ questionId, periodName, filters }) => {
  const { formatMessage } = useIntl()
  const [expanded, setExpanded] = useState(false)

  const { data: providersByExpirationDateArray, isLoading } = useFetchPost<ProvidersExpirationDate[], FilterGroup[]>({
    payload: [
      ...filters.filter(fi => !STATISTICS_SPECIAL_FILTERS.includes(fi.name)),
      {
        name: 'requestItem.questionId',
        filters: [
          {
            value: questionId,
            operator: Operators.EqualTo,
          },
        ],
      },
      {
        name: 'response.request.periodName',
        filters: [
          {
            value: periodName,
            operator: Operators.EqualTo,
          },
        ],
      },
    ],
    endpoint: endpoints.providersByExpirationDate,
    key: [FetchKey.ProvidersByExpirationDate, questionId],
    options: { enabled: expanded },
  })
  const categories: Category[] =
    providersByExpirationDateArray?.map(p => ({
      categoryText: formatMessage({ id: `statistics.${p.expirationDateInterval}` }),
      providers: p.providers,
    })) ?? []

  return (
    <DetailsSection expanded={expanded} setExpanded={setExpanded} isLoading={isLoading}>
      <ProvidersByCategory categories={categories} />
    </DetailsSection>
  )
}

export default ProvidersByFileExpiration
