import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchPost } from '@app/src/api/fetchHooks'
import SimpleSelect, { Option } from '@app/src/components/Form/Select/SimpleSelect'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import { FilterGroup } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { PeriodTemplateCombination } from '@app/src/types/resourceExplorer'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { FilterChildrenRenderProps } from './Filter'

interface TemplateAndPeriodFilterProps {
  value: string
  onChange: FilterChildrenRenderProps['onChange']
  allowedFilters: string[]
  filterName: string
}

const TemplateAndPeriodFilter: React.FC<TemplateAndPeriodFilterProps> = ({
  value,
  onChange,
  allowedFilters,
  filterName,
}) => {
  const { formatMessage } = useIntl()
  const [isOpen, setOpen] = useState<boolean>(false)
  const apiFilters = useGetApiQueryFilters(allowedFilters)
  const { data, isLoading: isLoadingPeriodFacet } = useFetchPost<PeriodTemplateCombination[], FilterGroup[]>({
    endpoint: endpoints.templateAndPeriodCombination,
    payload: apiFilters.filter(f => filterName !== f.name),
    key: FetchKey.ResponseItemsFacets,
  })

  const periodOptions =
    data?.map(
      s =>
        ({
          value: `${s.templateId}:${s.periodName}`,
          label: `${s.templateTitle} - ${s.periodName}`,
          additionalText: s.templateTitle,
        }) as Option<string>,
    ) ?? []

  return (
    <SimpleSelect<string>
      size="small"
      variant="outlined"
      onChange={value => onChange((value as Option<string>)?.value)}
      groupBy={option => option.additionalText ?? ''}
      open={isOpen}
      setOpen={setOpen}
      loading={isLoadingPeriodFacet}
      options={periodOptions}
      fieldLabel={formatMessage({ id: 'schemas.request.reportName' })}
      value={periodOptions.find(opt => opt.value === value) ?? null}
    />
  )
}
export default TemplateAndPeriodFilter
