import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchPost } from '@app/src/api/fetchHooks'
import DetailsSection from '@app/src/pages/ResourceCollection/Collections/DataHub/QuestionTypeCharts/DetailsSection'
import ProvidersByCategory, {
  Category,
} from '@app/src/pages/ResourceCollection/Collections/DataHub/QuestionTypeCharts/ProvidersByCategory'
import { STATISTICS_SPECIAL_FILTERS } from '@app/src/pages/ResourceCollection/Filters/StatisticsConstants'
import { FilterGroup, Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { GenericOrganization } from '@app/src/types/organizations'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'

interface ProvidersByNumbersChosenProps {
  questionId: number
  filters: FilterGroup[]
  periodName: string
  min: number | undefined
  max: number | undefined
}

interface ProviderByNumbersQuery {
  filters: FilterGroup[]
  minValue: number
  maxValue: number
}

interface ProviderByNumberChosen {
  numberChosenType: NumberChosenType
  providers: GenericOrganization[]
}

type NumberChosenType = 'other' | 'min' | 'max'

const ProvidersByNumbersChosen: React.FC<ProvidersByNumbersChosenProps> = ({
  questionId,
  periodName,
  min,
  max,
  filters,
}) => {
  const { formatMessage } = useIntl()
  const [expanded, setExpanded] = useState(false)

  const { data: providersByNumbersChosen, isLoading } = useFetchPost<ProviderByNumberChosen[], ProviderByNumbersQuery>({
    payload: {
      minValue: min ?? 0,
      maxValue: max ?? 0,
      filters: [
        ...filters.filter(fi => !STATISTICS_SPECIAL_FILTERS.includes(fi.name)),
        {
          name: 'requestItem.questionId',
          filters: [
            {
              value: questionId,
              operator: Operators.EqualTo,
            },
          ],
        },
        {
          name: 'response.request.periodName',
          filters: [
            {
              value: periodName,
              operator: Operators.EqualTo,
            },
          ],
        },
      ],
    },
    endpoint: endpoints.providersByNumbersChosen,
    key: [FetchKey.ProvidersByExpirationDate, questionId],
    options: { enabled: expanded && min !== undefined && max !== undefined },
  })

  const categories: Category[] =
    providersByNumbersChosen?.map(p => ({
      categoryText: formatMessage(
        { id: `statistics.numberChosenTypes.${p.numberChosenType.toLowerCase()}` },
        { orderedProvidersCount: providersByNumbersChosen.length },
      ),
      providers: p.providers,
    })) ?? []

  return (
    <DetailsSection expanded={expanded} setExpanded={setExpanded} isLoading={isLoading}>
      <ProvidersByCategory categories={categories} />
    </DetailsSection>
  )
}

export default ProvidersByNumbersChosen
