import { useDisplayValueWithUnit } from '@app/src/hooks/useDisplayValueWithUnit'
import { EmptyStateStatistics } from '@app/src/pages/ResourceCollection/Collections/DataHub/EmptyStateStatisticsProps'
import ProvidersByNumbersChosen from '@app/src/pages/ResourceCollection/Collections/DataHub/QuestionTypeCharts/Number/ProvidersByNumbersChosen'
import { NumberResult } from '@app/src/pages/ResourceCollection/Collections/DataHub/Reports/DataHubReport'
import { FilterGroup } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { Unit } from '@app/src/types/resourceExplorer'
import { formatNumber, insertIf } from '@app/src/utils/helpersTs'
import { br } from '@app/src/utils/translationMarkup'
import { Box } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import React from 'react'
import { useIntl } from 'react-intl'
import DisplayValue from '../../DisplayValue'

interface NumberStatsProps {
  numberResult: NumberResult | null
  unit?: Unit
  questionId: number
  filters: FilterGroup[]
  periodName: string
  companiesHaveAnswered: number
}

const NumberStats: React.FC<NumberStatsProps> = ({
  unit,
  numberResult,
  filters,
  questionId,
  periodName,
  companiesHaveAnswered,
}) => {
  const { formatMessage } = useIntl()
  const { getValueWithOptimalUnit, isLoadingUnits } = useDisplayValueWithUnit()

  if (!numberResult || !companiesHaveAnswered) {
    return <EmptyStateStatistics message={formatMessage({ id: 'statistics.noDataYet' }, { br })} />
  }

  const showSum = unit?.quantityType?.toLowerCase() !== 'fraction' && unit?.name?.toLowerCase() !== 'percentage'

  const dataToDisplay = [
    { stringId: 'general.average', value: numberResult.average },
    { stringId: 'general.min', value: numberResult.min },
    { stringId: 'general.max', value: numberResult.max },
    ...insertIf(showSum, { stringId: 'general.sum', value: numberResult.sum }),
  ].map(f => {
    const { value: newValue, unit: newUnit } = getValueWithOptimalUnit(f.value, unit)
    return { stringId: f.stringId, value: formatNumber(newValue) ?? '', unitSymbol: newUnit?.symbol ?? undefined }
  })

  if (isLoadingUnits) return <CircularProgress />

  return (
    <>
      <Box display="flex" justifyContent="space-between" marginBottom={2}>
        {dataToDisplay.map(({ stringId, value, unitSymbol }) => (
          <DisplayValue
            key={stringId}
            label={formatMessage({ id: stringId })}
            value={value}
            unitSymbol={unitSymbol}
            valueVariant="h1"
          />
        ))}
      </Box>
      <ProvidersByNumbersChosen
        min={numberResult?.min}
        max={numberResult.max}
        questionId={questionId}
        filters={filters}
        periodName={periodName}
      />
    </>
  )
}

export default NumberStats
