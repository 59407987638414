import { GenericOrganization } from '@app/src/types/organizations'
import { Box, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useIntl } from 'react-intl'

import { ProvidersForCategory } from '@app/src/pages/ResourceCollection/Collections/DataHub/QuestionTypeCharts/ProvidersForCategory'

export interface Category {
  categoryText: string
  providers: GenericOrganization[]
}

export interface ProvidersByCategoryProps {
  categories?: Category[]
}

const useStyles = makeStyles(({ spacing }) => ({
  optionValue: {
    marginRight: spacing(1),
  },
}))

const ProvidersByCategory: React.FC<ProvidersByCategoryProps> = ({ categories }) => {
  const { formatMessage } = useIntl()
  const classes = useStyles()
  return (
    <>
      {categories?.map(category => (
        <React.Fragment key={category.categoryText}>
          <Box display="flex" alignItems="baseline" flexWrap="wrap" mb={1} mt={2}>
            <Typography variant="body1" className={classes.optionValue}>
              {category.categoryText}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {formatMessage({ id: 'general.countCompany' }, { count: category.providers.length })}
            </Typography>
          </Box>
          <ProvidersForCategory providers={category.providers} />
        </React.Fragment>
      ))}
    </>
  )
}

export default ProvidersByCategory
