import { Box, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

interface OptionLabelProps {
  companyCount: number[]
  labels: string[]
}

export const OptionLabel: React.FC<OptionLabelProps> = ({ companyCount, labels }) => {
  const { formatMessage } = useIntl()

  return (
    <Box display="flex" mb={-3}>
      <Typography variant="body1">
        {formatMessage({ id: 'statistics.labelAnswer' }, { labels, b: (chunks: React.ReactNode) => <b>{chunks}</b> })}
        <Typography variant="caption" color="textSecondary">
          {formatMessage({ id: 'statistics.countCompany' }, { count: companyCount })}
        </Typography>
      </Typography>
    </Box>
  )
}
