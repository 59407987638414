import { EmptyStateStatistics } from '@app/src/pages/ResourceCollection/Collections/DataHub/EmptyStateStatisticsProps'
import { ITEM_TEMPLATE_ID_AND_PERIOD } from '@app/src/pages/ResourceCollection/Filters/StatisticsConstants'
import { FilterGroup, Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { GenericOrganization } from '@app/src/types/organizations'
import { br } from '@app/src/utils/translationMarkup'
import { useTheme } from '@mui/material'
import { EChartsOption } from 'echarts'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import { BarChart } from 'echarts/charts'
import { GridComponent, LegendComponent, TitleComponent, TooltipComponent } from 'echarts/components'
import * as echarts from 'echarts/core'
import { SVGRenderer } from 'echarts/renderers'
import { BarDataItemOption, BarItemStyleOption } from 'echarts/types/src/chart/bar/BarSeries'
import { TopLevelFormatterParams } from 'echarts/types/src/component/tooltip/TooltipModel'
import { TextCommonOption } from 'echarts/types/src/util/types'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { FileResult } from '../../Reports/DataHubReport'
import ProvidersByFileExpiration from './ProvidersByFileExpiration'

type FileChartProps = {
  fileResult: FileResult
  questionId: number
  filters: FilterGroup[]
  periodName: string
}

export interface ProvidersExpirationDate {
  expirationDateInterval: string
  providers: GenericOrganization[]
}

interface FileExpirationValues extends BarDataItemOption {
  value: number
  filters: unknown
  itemStyle: BarItemStyleOption
  percentage: number
  expirationDateInterval: string
  amount: number
}

const FilesChart: React.FC<FileChartProps> = ({ fileResult, questionId, periodName, filters }) => {
  const { palette, typography } = useTheme()
  const { formatMessage } = useIntl()
  const chartRef = useRef<HTMLDivElement>(null)
  const [width, setWidth] = useState<number>()

  useEffect(() => {
    if (chartRef.current) {
      setWidth(chartRef.current?.offsetWidth)
      window.addEventListener('resize', () => {
        setWidth(chartRef.current?.offsetWidth)
      })
    }
    return () => {
      window.removeEventListener('resize', () => {
        setWidth(chartRef.current?.offsetWidth)
      })
    }
  }, [chartRef.current])

  echarts.use([TitleComponent, TooltipComponent, GridComponent, BarChart, SVGRenderer, LegendComponent])

  const labels = fileResult.expirationDateItems.map(d => d.expirationDateInterval)

  const fileExpirationValues: Array<FileExpirationValues> = fileResult.expirationDateItems.map(d => ({
    value: d.percentage,
    filters: [
      ...filters.filter(fi => fi.name !== ITEM_TEMPLATE_ID_AND_PERIOD),
      {
        name: 'requestItem.template.id',
        filters: [
          {
            value: questionId,
            operator: Operators.EqualTo,
          },
        ],
      },
      {
        name: 'response.request.periodName',
        filters: [
          {
            value: periodName,
            operator: Operators.EqualTo,
          },
        ],
      },
    ],
    itemStyle: {
      borderRadius: [8, 8, 0, 0],
      color:
        d.expirationDateInterval.toLowerCase() === 'NoExpirationDate'.toLowerCase()
          ? palette.grey['500']
          : palette.secondary.main,
    },
    percentage: d.percentage,
    expirationDateInterval: d.expirationDateInterval,
    amount: d.amount,
  }))

  const options: EChartsOption = useMemo(
    () => ({
      xAxis: {
        type: 'category',
        axisLabel: {
          show: false,
        },
      },
      yAxis: {
        type: 'value',
        axisLine: {
          show: false,
        },
      },
      tooltip: {
        show: true,
        trigger: 'axis',
        axisPointer: { type: 'none' },
        position: 'inside',
        confine: true,
        formatter: (formattingItem: TopLevelFormatterParams) => {
          const { data } = Array.isArray(formattingItem) ? formattingItem[0] : formattingItem
          const fileExpirationData = data as FileExpirationValues
          return `${formatMessage({ id: `statistics.${fileExpirationData.expirationDateInterval}` })} <b>${
            fileExpirationData.percentage
          }%<b/> ${formatMessage({ id: 'statistics.companiesCount' }, { count: fileExpirationData.amount })}`
        },
      },
      textStyle: {
        fontFamily: typography.fontFamily,
        fontWeight: 500,
        color: palette.text.primary,
      },
      series: [
        {
          label: {
            position: 'bottom',
            show: true,
            formatter: (formattingItem: TopLevelFormatterParams) => {
              const { data } = Array.isArray(formattingItem) ? formattingItem[0] : formattingItem
              const fileExpirationData = data as FileExpirationValues
              return `{expirationDateFormat|${formatMessage({
                id: `statistics.${fileExpirationData.expirationDateInterval}`,
              })}} \n\n {percentageFormat|${fileExpirationData.percentage}%} \n\n {amountFormat|${formatMessage(
                { id: 'statistics.companiesCount' },
                { count: fileExpirationData.amount },
              )}}`
            },
            rich: {
              expirationDateFormat: {
                fontWeight: 400,
                fontSize: '1.75rem',
              } as TextCommonOption,
              percentageFormat: {
                fontWeight: 400,
                fontSize: '1.5rem',
              } as TextCommonOption,
              amountFormat: {
                fontFamily: typography.fontFamily,
                fontWeight: typography.caption.fontWeight,
                fontSize: typography.caption.fontSize,
                color: '#6E6E71',
              } as TextCommonOption,
            },
          },
          data: fileExpirationValues,
          type: 'bar',
          barWidth: 40,
          itemStyle: {
            borderType: 'solid',
          },
        },
      ],
    }),
    [fileExpirationValues],
  )

  if (!fileResult) {
    return <EmptyStateStatistics message={formatMessage({ id: 'statistics.noDataYet' }, { br })} />
  }
  if (!fileResult.totalCount) {
    return null
  }

  return (
    <div ref={chartRef}>
      <ReactEChartsCore
        opts={{ width: width }}
        option={options}
        echarts={echarts}
        style={{ minWidth: '100%', height: 90 * labels.length }}
      />
      <ProvidersByFileExpiration questionId={questionId} periodName={periodName} filters={filters} />
    </div>
  )
}

export default FilesChart
