import { BarChart } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import React, { ReactNode } from 'react'

interface EmptyStateStatisticsProps {
  message: ReactNode
}

export const EmptyStateStatistics: React.FC<EmptyStateStatisticsProps> = ({ message }) => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" p={20} borderRadius="8px">
      <Box mb={2}>
        <BarChart fontSize="large" />
      </Box>
      <Typography variant="body1" align="center">
        {message}
      </Typography>
    </Box>
  )
}
