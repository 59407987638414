import { Box, Grid, Typography } from '@mui/material'
import Dialog, { DialogProps } from '@app/src/components/Dialog'
import FlagAvatar from '@app/src/components/Flags/FlagAvatar'
import FlagSuggestedRiskAvatar from '@app/src/components/Flags/FlagSuggestedRiskAvatar'
import { AvatarSize } from '@app/src/components/Ui/Avatar'
import React from 'react'
import { useIntl } from 'react-intl'
import { WF_ORGANIZATION_ID } from '@app/src/wf-constants'
import { StatisticsByTemplateSectionModel } from './DataHubReport'

type FlagOverviewDialogProps = {
  title: string
  statisticsSections: StatisticsByTemplateSectionModel[]
} & Omit<DialogProps, 'content'>

const FlagOverviewDialog: React.FC<FlagOverviewDialogProps> = ({
  title,
  statisticsSections,
  onClose,
  ...dialogProps
}) => {
  const { formatMessage } = useIntl()

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      title={title}
      onClose={onClose}
      {...dialogProps}
      content={
        <>
          {statisticsSections.map(section => (
            <Grid container key={section.order}>
              {section.items
                .filter(item => item.flagResult.flaggedCount)
                .map((item, i) => (
                  <React.Fragment key={item.id}>
                    <Grid item xs={12} key={i} mt={2}>
                      <Box display="flex">
                        <Box display="flex">
                          {item.flagResult?.reportFlagRules?.some(
                            flagRule => flagRule.creatorOrganizationId === WF_ORGANIZATION_ID,
                          ) ? (
                            <FlagSuggestedRiskAvatar size={AvatarSize.Small} />
                          ) : (
                            <FlagAvatar size={AvatarSize.Small} />
                          )}
                          <Typography ml={0.5} variant="body2">
                            {item.flagResult.flaggedCount}
                          </Typography>
                        </Box>

                        <Typography variant="body1" display="flex" whiteSpace="pre-wrap" ml={2}>
                          {formatMessage(
                            { id: 'statistics.questionOrderAndTitle' },
                            {
                              sectionOrder: section.order,
                              itemOrder: i + 1,
                              title: item.title,
                              b: (chunks: React.ReactNode) => <b>{chunks}</b>,
                            },
                          )}
                        </Typography>
                      </Box>
                    </Grid>
                  </React.Fragment>
                ))}
            </Grid>
          ))}
        </>
      }
      buttons={[
        {
          label: formatMessage({ id: 'general.close' }),
          onClick: onClose,
        },
      ]}
    />
  )
}

export default FlagOverviewDialog
