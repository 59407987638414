import React from 'react'

import FilesChart from '@app/src/pages/ResourceCollection/Collections/DataHub/QuestionTypeCharts/Files/FilesChart'
import NumberStats from '@app/src/pages/ResourceCollection/Collections/DataHub/QuestionTypeCharts/Number/NumberStats'
import OptionsChart from '@app/src/pages/ResourceCollection/Collections/DataHub/QuestionTypeCharts/Options/OptionsChart'
import TextAnswers from '@app/src/pages/ResourceCollection/Collections/DataHub/QuestionTypeCharts/Text/TextAnswers'
import { FilterGroup } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { Unit } from '@app/src/types/resourceExplorer'
import { QuestionTypes } from '@app/src/wf-constants'
import { FileResult, NumberResult, OptionsResult } from '../Reports/DataHubReport'

interface DataHubTemplateChartsProps {
  numberResult: NumberResult | null
  questionType?: string
  unit?: Unit
  filters: FilterGroup[]
  questionId: number
  optionsResult: OptionsResult
  fileResult: FileResult
  periodName: string
  companiesHaveAnswered: number
}

const DataHubCharts: React.FC<DataHubTemplateChartsProps> = ({
  numberResult,
  questionType,
  unit,
  filters,
  questionId,
  optionsResult,
  fileResult,
  periodName,
  companiesHaveAnswered,
}) => {
  switch (questionType) {
    case QuestionTypes.Text:
      return <TextAnswers questionId={questionId} periodName={periodName} filters={filters} />
    case QuestionTypes.File:
      return <FilesChart fileResult={fileResult} questionId={questionId} periodName={periodName} filters={filters} />
    case QuestionTypes.Options:
      return (
        <OptionsChart optionsResult={optionsResult} questionId={questionId} periodName={periodName} filters={filters} />
      )
    case QuestionTypes.Number:
      return (
        <NumberStats
          unit={unit}
          numberResult={numberResult}
          periodName={periodName}
          filters={filters}
          questionId={questionId}
          companiesHaveAnswered={companiesHaveAnswered}
        />
      )
    default:
      return null
  }
}

export default DataHubCharts
