import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchFacets } from '@app/src/api/fetchHooks'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import { FacetItem } from '@app/src/pages/ResourceCollection'
import { ChartType } from '@app/src/pages/ResourceCollection/Collections/Assessments/ChartTypeSelector'
import DataHubCard from '@app/src/pages/ResourceCollection/Collections/DataHub/DataInsights/DataHubCard'
import DataHubChartTypeSelector from '@app/src/pages/ResourceCollection/Collections/DataHub/DataInsights/DataHubChartTypeSelector'
import { SummaryChartDataTypes } from '@app/src/pages/ResourceCollection/Collections/DataHub/DataInsights/useSummaryChartConfigs'
import { StatisticsForQuestionPerPeriod } from '@app/src/pages/ResourceCollection/Collections/DataHub/Reports/DataHubReport'
import {
  getProviderIdsFromFilter as getProviderOrganizationIdsFromFilter,
  removeQuestionFilters,
  transformFilterNames,
} from '@app/src/pages/ResourceCollection/Filters/StatisticsConstants'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { InquiryStatus } from '@app/src/types/resourceExplorer'
import { comparePeriods } from '@app/src/utils/getOrderedPeriods'
import { insertIf } from '@app/src/utils/helpersTs'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import { uniq } from 'lodash'
import React, { useEffect, useState } from 'react'
import { dataHubAllowedFilters } from '.'
import { GraphSkeleton } from './DataHubInsights'
import DataHubSummaryChart, { StatisticsSummary, StatisticsSummaryPerPeriod } from './DataHubSummaryChart'
import { useDataInsightsModal } from './DataInsightsModalContext'

interface DataHubSummaryCardProps {
  statistics: StatisticsForQuestionPerPeriod
  index: number
}

const toStatisticsSummaryResult = (
  statistics: StatisticsForQuestionPerPeriod,
  noResponseItems: FacetItem[],
): StatisticsSummary => {
  const totalPeriods = uniq([
    ...statistics.resultForPeriods.map(r => r.period),
    ...noResponseItems.map(r => r.value.toString()),
  ]).sort(comparePeriods)

  const statisticsSummaryResult: StatisticsSummaryPerPeriod[] = totalPeriods.map(period => {
    const resultPeriod = statistics.resultForPeriods.find(r => r.period === period)
    const unansweredQuestionCount = noResponseItems.find(p => p.value === period)?.count ?? 0

    return {
      period,
      companiesAnswered: resultPeriod?.questionMonitoring?.companiesHaveAnswered?.number ?? 0,
      companiesAnsweredDontHaveThis: resultPeriod?.questionMonitoring?.companiesHaveNotAnswered?.number ?? 0,
      companiesNotAnswered: unansweredQuestionCount,
    }
  })

  return {
    id: statistics.id,
    title: statistics.title,
    resultForPeriods: statisticsSummaryResult,
  }
}

const DataHubSummaryCard: React.FC<DataHubSummaryCardProps> = ({ statistics, index }) => {
  const eChartsRef = React.useRef<null | ReactEChartsCore>(null)
  const [selectedChartType, setSelectedChartType] = useState<ChartType>(ChartType.Vertical)
  const { openDataInsightsModal, setDataInsightsRefForIndex } = useDataInsightsModal()
  const userFilters = useGetApiQueryFilters(dataHubAllowedFilters)

  const cleanFilters = removeQuestionFilters(userFilters)
  const renamedFiltersForInquiries = transformFilterNames(cleanFilters)

  const providerOrganizationIds = getProviderOrganizationIdsFromFilter(userFilters)

  useEffect(() => {
    setDataInsightsRefForIndex(eChartsRef, index)
  }, [index, setDataInsightsRefForIndex])

  const { facets, isLoading } = useFetchFacets({
    key: FetchKey.InquiryStatus,
    endpoint: endpoints.inquiryWithFacets,
    facetsParam: [{ name: 'periodName', isEnum: true }],
    filter: [
      ...renamedFiltersForInquiries,
      {
        name: 'template.sections.questions.id',
        filters: [
          {
            operator: Operators.EqualTo,
            value: statistics.id,
          },
        ],
      },
      {
        name: 'status',
        filters: [{ operator: Operators.EqualTo, value: InquiryStatus.Requested }],
      },
      ...insertIf(Boolean(providerOrganizationIds), {
        name: 'provider.organizationId',
        filters: [
          {
            operator: Operators.In,
            value: providerOrganizationIds,
          },
        ],
      }),
    ],
    skipNotSet: true,
  })

  if (isLoading) {
    return <GraphSkeleton />
  }

  return (
    <DataHubCard
      statistics={statistics}
      eChartsRef={eChartsRef}
      onClickTitle={() =>
        openDataInsightsModal(statistics, {
          summaryType: [SummaryChartDataTypes.CompaniesAnswered, SummaryChartDataTypes.CompaniesAnsweredDontHaveThis],
        })
      }
      chartSelector={
        <DataHubChartTypeSelector
          selectedChartType={selectedChartType}
          setSelectedChartType={setSelectedChartType}
          statistics={statistics}
          isSummaryView
        />
      }
    >
      <DataHubSummaryChart
        statistics={toStatisticsSummaryResult(statistics, facets.flat())}
        echartsRef={eChartsRef}
        selectedChartType={selectedChartType}
      />
    </DataHubCard>
  )
}

export default DataHubSummaryCard
