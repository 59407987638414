import { Box, Divider } from '@mui/material'
import CompanyCell from '@app/src/components/Table/Cells/CompanyCell'
import React from 'react'
import { GenericOrganization } from '@app/src/types/organizations'

interface ProvidersForOptionProps {
  providers: GenericOrganization[]
}

export const ProvidersForCategory: React.FC<ProvidersForOptionProps> = ({ providers }) => {
  if (!providers || providers.length === 0) return <></>

  return (
    <Box>
      <Box display="flex" flexWrap="wrap">
        {providers.map(provider => (
          <Box key={provider.id} mr={2} mb={1}>
            <CompanyCell company={provider} disableCell drawer />
          </Box>
        ))}
      </Box>
      <Box my={2}>
        <Divider />
      </Box>
    </Box>
  )
}
