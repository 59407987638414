import StatisticsCard from '@app/src/pages/Dashboards/StatisticsCard'
import { FilterGroup } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { QuestionnaireStatistics } from '@app/src/types/resourceExplorer'
import { Grid, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

interface OverviewCardsSectionProps {
  relatedContentFilter?: FilterGroup[]
  overviewStats?: QuestionnaireStatistics
}

const OverviewCardsSection: React.FC<OverviewCardsSectionProps> = ({ overviewStats }) => {
  const { formatMessage } = useIntl()

  return (
    <>
      <Grid display="flex" container spacing={2} justifyContent="left">
        <Grid item xs={2.4}>
          <StatisticsCard
            justifyContent="left"
            title={formatMessage({ id: 'statistics.overviewRequests.questionnaireTitle' })}
          >
            <Typography pt={1} variant="h6">
              {overviewStats?.totalQuestionnaires ?? '0%'}
            </Typography>
          </StatisticsCard>
        </Grid>
        <Grid item xs={2.4}>
          <StatisticsCard
            justifyContent="left"
            title={formatMessage({ id: 'statistics.overviewRequests.totalRequests' })}
          >
            <Typography pt={1} variant="h4">
              {overviewStats?.totalRequests ?? formatMessage({ id: 'general.noData' })}
            </Typography>
          </StatisticsCard>
        </Grid>
        <Grid item xs={2.4}>
          <StatisticsCard
            justifyContent="left"
            title={formatMessage({ id: 'statistics.overviewRequests.requestsReported' })}
          >
            <Typography pt={1} variant="h4">
              {overviewStats?.reportedRequests ?? formatMessage({ id: 'general.noData' })}
            </Typography>
          </StatisticsCard>
        </Grid>
        <Grid item xs={2.4}>
          <StatisticsCard
            justifyContent="left"
            title={formatMessage({ id: 'statistics.overviewRequests.responseRate' })}
          >
            <Typography pt={1} variant="h4">
              {overviewStats?.percentResponseRate
                ? `${overviewStats.percentResponseRate}%`
                : formatMessage({ id: 'general.noData' })}
            </Typography>
          </StatisticsCard>
        </Grid>
        <Grid item xs={2.4}>
          <StatisticsCard
            justifyContent="left"
            title={formatMessage({ id: 'statistics.overviewRequests.companiesEngaged' })}
          >
            <Typography pt={1} variant="h4" data-testid="request-overview-engaged-companies">
              {overviewStats?.companiesEngaged ?? formatMessage({ id: 'general.noData' })}
            </Typography>
          </StatisticsCard>
        </Grid>
      </Grid>
    </>
  )
}

export default OverviewCardsSection
