import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchFacets } from '@app/src/api/fetchHooks'
import { TabsProps } from '@app/src/components/Tabs'
import { FilterGroup, Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { ProviderLinkStatus } from '@app/src/types/organizations'
import paths from '@app/src/wf-constants/paths'
import React from 'react'
import { useIntl } from 'react-intl'
import { Redirect, Route, Switch, generatePath, useParams } from 'react-router'
import AllProvidersScene from './AllProvidersScene'
import ConnectedProvidersScene from './ConnectedProvidersScene'
import MissingContactProvidersScene from './MissingContactProvidersScene'
import NotConnectedProvidersScene from './NotConnectedProvidersScene'

interface ProvidersCollectionProps {
  relatedContentFilter?: FilterGroup[]
}

export enum ProviderTabs {
  All = 'all',
  Connected = 'connected',
  NotConnected = 'not-connected',
  MissingContacts = 'missing-contacts',
}

interface ProvidersCollectionSceneParams {
  view: string
}

const allowedFilters = [
  'id',
  'name',
  'country.id',
  'createdAt',
  'ownerUserId',
  'websiteAddress',
  'vatNumber',
  'registrationNumber',
  'linkStatus',
  'categoryOptions.id',
  'activityStatus',
  'finalRiskRating',
  'priority',
  'providerApprovalStatus',
  'supplierUsage',
  'tier',
  'customId',
  'organization.id',
  'mappingNodes.actorTypeModel.id',
  'mappingNodes.tier',
]

const ProvidersCollection: React.FC<ProvidersCollectionProps> = ({ relatedContentFilter = [] }) => {
  const { formatMessage } = useIntl()
  const { view } = useParams<ProvidersCollectionSceneParams>()

  const { count: referralContactCount } = useFetchFacets({
    key: FetchKey.ProviderReferralContactFacets,
    endpoint: endpoints.providersWithFacets,
    filter: [
      {
        name: 'linkStatus',
        filters: [
          {
            value: ProviderLinkStatus.Created,
            operator: Operators.EqualTo,
          },
        ],
      },
      {
        name: 'referralContact.email',
        filters: [
          {
            operator: Operators.IsNull,
          },
        ],
      },
    ],
    facetsParam: [{ name: 'id' }],
  })

  const actionTabs: TabsProps['tabs'] = [
    {
      type: ProviderTabs.All,
      url: generatePath(paths.providers, { view: ProviderTabs.All }),
      label: formatMessage({ id: 'resourceExplorer.provider.all' }),
    },
    {
      type: ProviderTabs.Connected,
      url: generatePath(paths.providers, { view: ProviderTabs.Connected }),
      label: formatMessage({ id: 'resourceExplorer.provider.connected' }),
    },
    {
      type: ProviderTabs.NotConnected,
      url: generatePath(paths.providers, { view: ProviderTabs.NotConnected }),
      label: formatMessage({ id: 'resourceExplorer.provider.notConnected' }),
    },
    {
      type: ProviderTabs.MissingContacts,
      url: generatePath(paths.providers, { view: ProviderTabs.MissingContacts }),
      label: formatMessage({ id: 'resourceExplorer.provider.missingContact' }),
      hidden: !referralContactCount,
    },
  ]

  if (!view) {
    return (
      <Redirect
        to={generatePath(paths.providers, {
          view: ProviderTabs.All,
        })}
      />
    )
  }

  return (
    <Switch>
      <Route path={generatePath(paths.providers, { view: ProviderTabs.All })}>
        <AllProvidersScene
          tabs={{ actionTabs, activeTabParam: view }}
          allowedFilters={allowedFilters}
          relatedContentFilter={relatedContentFilter}
        />
      </Route>
      <Route path={generatePath(paths.providers, { view: ProviderTabs.Connected })}>
        <ConnectedProvidersScene
          tabs={{ actionTabs, activeTabParam: view }}
          allowedFilters={allowedFilters}
          relatedContentFilter={relatedContentFilter}
        />
      </Route>
      <Route path={generatePath(paths.providers, { view: ProviderTabs.NotConnected })}>
        <NotConnectedProvidersScene
          tabs={{ actionTabs, activeTabParam: view }}
          allowedFilters={allowedFilters}
          relatedContentFilter={relatedContentFilter}
        />
      </Route>
      <Route path={generatePath(paths.providers, { view: ProviderTabs.MissingContacts })}>
        <MissingContactProvidersScene
          tabs={{ actionTabs, activeTabParam: view }}
          allowedFilters={allowedFilters}
          relatedContentFilter={relatedContentFilter}
          referralContactCount={referralContactCount}
        />
      </Route>
    </Switch>
  )
}
export default ProvidersCollection
