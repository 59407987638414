import InfoIcon from '@mui/icons-material/Info'
import { alpha, Box, Tooltip, Typography, TypographyProps, useTheme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useIntl } from 'react-intl'

const useStyles = makeStyles({
  header: {
    textTransform: 'uppercase',
  },
})

interface DisplayValueProps {
  label: string
  value: string
  companiesNumber?: number
  textAlign?: 'right' | 'left'
  guidanceText?: string
  unitSymbol?: string
  valueVariant?: TypographyProps['variant']
}

const DisplayValue: React.FC<DisplayValueProps> = ({
  label,
  value,
  companiesNumber,
  textAlign = 'left',
  guidanceText,
  unitSymbol,
  valueVariant,
}) => {
  const { formatMessage } = useIntl()
  const classes = useStyles()
  const { palette } = useTheme()

  return (
    <Box textAlign={textAlign}>
      <Box display="flex" alignItems="center" justifyContent={textAlign === 'right' ? 'flex-end' : 'flex-start'}>
        <Typography className={classes.header} color="textSecondary" variant="caption" noWrap>
          {label}
        </Typography>
        {guidanceText && (
          <Tooltip title={<Typography>{guidanceText}</Typography>}>
            <Box fontSize={16} ml={0.5} color={alpha(palette.text.secondary, 0.4)} display="flex" alignItems="center">
              <InfoIcon fontSize="inherit" color="inherit" />
            </Box>
          </Tooltip>
        )}
      </Box>
      <Box display="flex" justifyContent={textAlign === 'right' ? 'flex-end' : 'flex-start'}>
        <Typography variant={valueVariant ?? 'h6'}>{value}</Typography>
      </Box>
      {companiesNumber !== undefined && companiesNumber !== null && (
        <Typography variant="caption">
          {formatMessage(
            {
              id: 'statistics.companiesCount',
            },
            { count: companiesNumber },
          )}
        </Typography>
      )}
      {(companiesNumber === undefined || companiesNumber === null) &&
        unitSymbol !== undefined &&
        unitSymbol !== null && (
          <Box display="flex" alignItems="end">
            <Typography noWrap variant="body1">
              {unitSymbol}
            </Typography>
          </Box>
        )}
    </Box>
  )
}

export default DisplayValue
